import { isFunction } from 'lodash'
import { useRef, useContext, useState, useEffect, createContext, ReactElement } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

import { CloseIcon } from 'components/Icons/CloseIcon'

// @ts-expect-error TS(2307)
const Context = createContext()

const ModalProviderContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
`

export const ModalProvider = ({ children }: TSFixMe): ReactElement => {
  const modalRef = useRef()
  const [context, setContext] = useState()

  useEffect(() => {
    setContext(modalRef.current)
  }, [])

  return (
    <ModalProviderContainer>
      <Context.Provider value={context}>{children}</Context.Provider>
      {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div ref={modalRef} />
    </ModalProviderContainer>
  )
}

const fadeIn = keyframes`from { opacity: 0; }`

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  overflow: auto;
`

const Dialog = styled.div<{ large?: boolean; xlarge?: boolean }>`
  position: ${({ large, xlarge }): string => (large || xlarge ? 'relative' : 'absolute')};
  top: ${({ large, xlarge }): string => (large || xlarge ? '50px' : '50%')};
  left: ${({ large, xlarge }): string => (large || xlarge ? '0px' : '50%')};
  transform: ${({ large, xlarge }): string => (large || xlarge ? 'unset' : 'translate(-50%, -50%)')};
  background: #fff;
  z-index: 1000;
  width: calc(100% - 60px);
  max-width: ${({ large, xlarge }): string => (xlarge ? '1216px' : large ? '700px' : '500px')};
  margin: auto;
  border-radius: 8px;
`

const Wrapper = styled.div`
  padding: 32px;
`

const Container = ({ children, ...props }: TSFixMe): ReactElement | null => {
  const modalNode = useContext(Context)

  return modalNode
    ? ReactDOM.createPortal(
        <Overlay>
          <Dialog {...props}>
            <Wrapper>{children}</Wrapper>
          </Dialog>
        </Overlay>,
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        modalNode
      )
    : null
}

const ModalHeader = styled.div<{ isShowingCloseIcon?: boolean; smallMarginBottom?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ smallMarginBottom }): string => (smallMarginBottom ? '20px' : '38px')};
`

const HeaderContent = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #28265d;
`

const CloseModal = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  svg {
    fill: #5d5c70;
    width: 27px;
    height: 27px;
  }
`

const Header = ({ children, onClose, smallMarginBottom, ...props }: TSFixMe): ReactElement => {
  const isShowingCloseIcon = isFunction(onClose)

  return (
    <ModalHeader isShowingCloseIcon={isShowingCloseIcon} smallMarginBottom={smallMarginBottom} {...props}>
      <HeaderContent>{children}</HeaderContent>
      {isShowingCloseIcon && (
        <CloseModal onClick={onClose}>
          <CloseIcon />
        </CloseModal>
      )}
    </ModalHeader>
  )
}

const Content = styled.div`
  color: #28265d;
  font-size: 16px;
  line-height: 18.75px;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  margin-top: 20px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const ErrorMessage = styled.div`
  text-align: center;
`

export default {
  Container,
  Header,
  Content,
  Footer,
  Actions,
  ErrorMessage
}
