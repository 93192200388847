export enum EFeatureFlagsNames {
  /**
   * This flag is used for the 'Day Frequency' option
   * in the Kredit Settings form. It is used for testing and QA purposes.
   */
  DayFrecuencyKreditsSettings = 'day-frecuency-kredits-settings',
  /**
   * Related to https://kolors.atlassian.net/browse/NA-12793
   */
  BookingCancellationV2 = 'booking-cancellation-update-V2'
}

export const featureFlagsValues = [
  {
    name: EFeatureFlagsNames.DayFrecuencyKreditsSettings,
    envValue: process.env.DAY_FRECUENCY_KREDITS_SETTINGS === 'true'
  },
  {
    name: EFeatureFlagsNames.BookingCancellationV2,
    envValue: process.env.BOOKING_CANCELLATION_UPDATE_V2 === 'true'
  }
]
