import { ApolloClient, ApolloLink, split, gql, GraphQLRequest } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { InMemoryCache } from '@apollo/client/cache'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws'
import jwtDecode from 'jwt-decode'

import { getStatusCode } from 'utils/error'
import { bookingsLocalOnlyFields } from 'local_only_fields/bookings'
import { IAccessToken, IJWTAccessToken, IJWTRefreshToken, IRefreshToken } from '../features/Login/types'
import { GTTripV2ItineraryStop } from 'gql/types.generated'

const cache = new InMemoryCache({
  typePolicies: {
    BookingV2TicketAncillary: {
      keyFields: ['id', 'status', 'quantity', 'unitPrice', 'total']
    },
    BookingV2Trip: {
      keyFields: ['id', 'segment', ['id']]
    },
    BookingV2Passenger: {
      keyFields: ['id', 'properties', ['id']]
    },
    BookingV2PaymentTicket: {
      keyFields: ['id', 'subtotal', 'vat', 'discount']
    },
    BookingV2TripSegment: {
      keyFields: ['id', 'departureAt']
    },
    RouteServiceBusinessClient: {
      keyFields: ['id', 'businessSites', ['id']]
    },
    TripVehicle: {
      keyFields: ['id', 'schedule', ['type']]
    },
    TripV2Vehicle: {
      keyFields: ['id', 'schedule', ['type']]
    },
    TripRouteServiceBusinessClient: {
      keyFields: ['id', 'businessSites', ['id']]
    },
    TripV2RouteServiceBusinessClient: {
      keyFields: ['id', 'businessSites', ['id']]
    },
    TripV2: {
      fields: {
        firstStop: {
          read(_, { readField }): GTTripV2ItineraryStop {
            const itinerary = readField('itinerary') as GTTripV2ItineraryStop[]
            return itinerary?.at(0) as GTTripV2ItineraryStop
          }
        },
        lastStop: {
          read(_, { readField }): GTTripV2ItineraryStop {
            const itinerary = readField('itinerary') as GTTripV2ItineraryStop[]
            return itinerary?.at(-1) as GTTripV2ItineraryStop
          }
        }
      }
    },
    TripV2Driver: {
      keyFields: ['id', 'RSVP', ['status', 'attempt']]
    },
    ...bookingsLocalOnlyFields
  }
})

const GET_LOGIN = gql`
  query GetLogin {
    login @client {
      accessToken {
        policies
        conditions
        companyIds
        companyId
        expirationDate
        value
      }
      refreshToken {
        expirationDate
        value
      }
    }
  }
`

const REFRESH_SESSION = gql`
  mutation RefreshSession($refreshToken: String!) {
    refreshSession(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
    }
  }
`

const SET_TOKENS = gql`
  mutation SetTokens($accessToken: String!, $refreshToken: String!) {
    setTokens(accessToken: $accessToken, refreshToken: $refreshToken) @client
  }
`

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

const REMOVE_TOKENS = gql`
  mutation RemoveTokens {
    removeTokens @client
  }
`

export const isValidToken = (token: IRefreshToken): boolean => {
  if (token.value) {
    const now = new Date()

    return now.getTime() < token.expirationDate * 1000
  }

  return false
}

const refreshSessionEvent = {
  _loading: false,
  _listeners: [],
  get loading(): boolean {
    return this._loading
  },
  set loading(val) {
    this._loading = val
    this._listeners.forEach((_listener: (value: boolean) => void): void => _listener(val))
    this._listeners = []
  },
  registerListener: function (listener: (value: boolean) => void): void {
    this._listeners = [...this._listeners, listener]
  }
}

const waitForRefreshSession = (): Promise<void> => {
  return new Promise((resolve) => {
    refreshSessionEvent.registerListener(() => {
      resolve()
    })
  })
}

export const refreshSession = async (
  refreshToken: IRefreshToken,
  operation?: GraphQLRequest
): Promise<{ accessToken?: string; refreshToken?: string }> => {
  if (!refreshSessionEvent.loading) {
    refreshSessionEvent.loading = true

    try {
      const { data } = await client.mutate({
        mutation: REFRESH_SESSION,
        variables: {
          refreshToken: refreshToken.value
        }
      })

      if (operation?.operationName !== 'Logout') {
        await client.mutate({
          mutation: SET_TOKENS,
          variables: {
            accessToken: data.refreshSession.accessToken,
            refreshToken: data.refreshSession.refreshToken
          }
        })
      }
      refreshSessionEvent.loading = false
      return data.refreshSession
    } catch (ex) {
      refreshSessionEvent.loading = false
    }
  } else {
    await waitForRefreshSession()

    const { login } = client.readQuery({ query: GET_LOGIN })

    return {
      accessToken: login.accessToken.value,
      refreshToken: login.refreshToken.value
    }
  }

  return {}
}

export const logout = async (): Promise<void> => {
  const { login } = client.readQuery({ query: GET_LOGIN })

  if (login.accessToken.value) {
    client.mutate({
      mutation: LOGOUT
    })
    await client.mutate({
      mutation: REMOVE_TOKENS
    })
  }
}

const withToken = setContext(async (operation) => {
  const { login } = client.readQuery({ query: GET_LOGIN })

  if (login.accessToken.value && operation.operationName !== 'RefreshSession') {
    const hasValidAccessToken = isValidToken(login.accessToken)
    const hasValidRefreshToken = isValidToken(login.refreshToken)

    if (hasValidAccessToken) {
      return {
        headers: {
          Authorization: `Bearer ${login.accessToken.value}`
        }
      }
    } else if (!hasValidAccessToken && hasValidRefreshToken) {
      try {
        const result = await refreshSession(login.refreshToken, operation)

        return {
          headers: {
            Authorization: `Bearer ${result.accessToken}`
          }
        }
      } catch (ex) {
        // Do nothing
      }
    } else {
      await logout()
      return {}
    }
  }

  return {}
})

const resetToken = onError((error) => {
  if (getStatusCode(error) === 401 && error.operation.operationName !== 'Logout') {
    logout()
  }
})

const authFlowLink = withToken.concat(resetToken)

const httpLink = ApolloLink.from([
  createUploadLink({
    uri: `${process.env.API_URL}/graphql`
  })
])

export const wsClient = createClient({
  url: process.env.WS_URL ?? '',
  lazy: true
})

const wsLink = new GraphQLWsLink(wsClient)

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authFlowLink, link])
})

export const initCacheData = ({ lastUrl }: { lastUrl?: string } = {}): void => {
  let accessToken: Partial<IAccessToken> = {}
  let refreshToken: Partial<IRefreshToken> = {}

  try {
    const accessTokenValue = localStorage.getItem('nexbus_at') ?? ''
    const refreshTokenValue = localStorage.getItem('nexbus_rt') ?? ''

    try {
      const { pol, cds, com, exp } = jwtDecode(accessTokenValue) as IJWTAccessToken
      const { exp: refreshTokenExpiration } = jwtDecode(refreshTokenValue) as IJWTRefreshToken

      accessToken = {
        policies: pol || [],
        conditions: cds || [],
        companyIds: com || [],
        companyId: com?.at(0) || '',
        expirationDate: exp,
        value: accessTokenValue
      }
      refreshToken = {
        expirationDate: refreshTokenExpiration,
        value: refreshTokenValue
      }
    } catch (ex) {
      // Do nothing
    }
  } catch (ex) {
    // Do nothing
  }

  const data = {
    urls: {
      last: lastUrl || '/'
    },
    login: {
      __typename: 'Login',
      accessToken: {
        __typename: 'AccessToken',
        policies: accessToken.policies || [],
        conditions: accessToken.conditions || [],
        companyIds: accessToken.companyIds || [],
        companyId: accessToken.companyId || '',
        expirationDate: accessToken.expirationDate || null,
        value: accessToken.value || null
      },
      refreshToken: {
        __typename: 'RefreshToken',
        expirationDate: refreshToken.expirationDate || null,
        value: refreshToken.value || null
      }
    }
  }

  cache.writeQuery({
    query: gql`
      query GetLocalData {
        urls {
          last
        }
        login {
          accessToken {
            expirationDate
            policies
            conditions
            companyId
            companyIds
            value
          }
          refreshToken {
            expirationDate
            value
          }
        }
      }
    `,
    data
  })
}

initCacheData()
