import styled from 'styled-components'
import { ReactElement } from 'react'
import { IIconProps } from './types'

const Svg = styled.svg`
  width: 24px;
  height: 24px;
  fill: #b0b0b0;
`

export const SteeringWheelIcon = ({ className }: IIconProps): ReactElement => {
  return (
    <Svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M12 22.7539C10.6333 22.7539 9.34167 22.4914 8.125 21.9664C6.90833 21.4414 5.84583 20.7247 4.9375 19.8164C4.02917 18.9081 3.3125 17.8456 2.7875 16.6289C2.2625 15.4122 2 14.1206 2 12.7539C2 11.3706 2.2625 10.0747 2.7875 8.86641C3.3125 7.65807 4.02917 6.59974 4.9375 5.69141C5.84583 4.78307 6.90833 4.06641 8.125 3.54141C9.34167 3.01641 10.6333 2.75391 12 2.75391C13.3833 2.75391 14.6792 3.01641 15.8875 3.54141C17.0958 4.06641 18.1542 4.78307 19.0625 5.69141C19.9708 6.59974 20.6875 7.65807 21.2125 8.86641C21.7375 10.0747 22 11.3706 22 12.7539C22 14.1206 21.7375 15.4122 21.2125 16.6289C20.6875 17.8456 19.9708 18.9081 19.0625 19.8164C18.1542 20.7247 17.0958 21.4414 15.8875 21.9664C14.6792 22.4914 13.3833 22.7539 12 22.7539ZM11.25 21.2039V17.3539C10.2833 17.1872 9.45417 16.7456 8.7625 16.0289C8.07083 15.3122 7.61667 14.4706 7.4 13.5039H3.55C3.71667 15.5706 4.525 17.3247 5.975 18.7664C7.425 20.2081 9.18333 21.0206 11.25 21.2039ZM12.75 21.2039C14.8333 21.0372 16.5958 20.2289 18.0375 18.7789C19.4792 17.3289 20.2833 15.5706 20.45 13.5039H16.6C16.3667 14.4706 15.9125 15.3122 15.2375 16.0289C14.5625 16.7456 13.7333 17.1872 12.75 17.3539V21.2039ZM3.55 12.0039H7.35L10.15 9.05391H13.85L16.65 12.0039H20.45C20.2667 9.80391 19.3625 7.96224 17.7375 6.47891C16.1125 4.99557 14.2 4.25391 12 4.25391C9.8 4.25391 7.8875 4.99557 6.2625 6.47891C4.6375 7.96224 3.73333 9.80391 3.55 12.0039Z" />
    </Svg>
  )
}
