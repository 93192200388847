import { GET_URLS } from 'features/App/queries-legacy'

export const resolvers = {
  Mutation: {
    setLastUrl(_root: TSFixMe, { url }: TSFixMe, { cache }: TSFixMe): void {
      const { urls } = cache.readQuery({ query: GET_URLS })

      cache.writeQuery({
        query: GET_URLS,
        data: {
          urls: {
            ...urls,
            last: url
          }
        }
      })
    }
  }
}
