import { CloseIcon } from 'components/Icons/CloseIcon'
import { ClickAwayListener } from 'components/v2/ClickAwayListener'
import { IDrawerContainerProps, IDrawerProviderProps } from 'components/v2/Drawer/types'
import { ReactElement, createContext, useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

// @ts-expect-error TS(2307)
const Context = createContext()

const DrawerProviderContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
`

export const DrawerProvider = ({ children }: IDrawerProviderProps): ReactElement => {
  const drawerRef = useRef()
  const [context, setContext] = useState()

  useEffect(() => {
    setContext(drawerRef.current)
  }, [])

  return (
    <DrawerProviderContainer>
      <Context.Provider value={context}>{children}</Context.Provider>
      {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div ref={drawerRef} />
    </DrawerProviderContainer>
  )
}

const fadeIn = keyframes`from { opacity: 0; }`

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  overflow: auto;
`

const slideIn = keyframes`from { transform: translate(100%)} to{ transform: translate(0)}`

const Dialog = styled.div`
  animation: ${slideIn} 400ms ease;
  background: #fff;
  border-radius: 8px 0px 0px 8px;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 520px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 90%;
  z-index: 1000;
`

const Padding = styled.div`
  padding: 24px 32px;
  height: inherit;
  box-sizing: border-box;
`

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`

const CloseDrawer = styled.div`
  cursor: pointer;

  svg {
    fill: #5d5c70;
    width: 24px;
    height: 24px;
  }
`

const Container = ({ isOpen = false, onClose, children }: IDrawerContainerProps): ReactElement | null => {
  const drawerNode = useContext(Context)

  return isOpen && drawerNode
    ? ReactDOM.createPortal(
        <Overlay>
          <ClickAwayListener onClickAway={(): void => onClose?.()}>
            <Dialog>
              <Padding>
                <CloseWrapper onClick={(): void => onClose?.()}>
                  <CloseDrawer>
                    <CloseIcon />
                  </CloseDrawer>
                </CloseWrapper>
                {children}
              </Padding>
            </Dialog>
          </ClickAwayListener>
        </Overlay>,
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        drawerNode
      )
    : null
}

export default {
  Container
}
