import { ReactElement, Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'
import { Navigate } from 'react-router-dom'

const VehiclesV2ListView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "VehiclesV2ListView" */ /* webpackPrefetch: true */ './views/Index')
)
const NewVehiclesV2View = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "NewVehiclesV2View" */ /* webpackPrefetch: true */ './views/New')
)
const EditVehiclesV2View = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "EditVehiclesV2View" */ /* webpackPrefetch: true */ './views/Edit')
)

export const VehiclesV2Routes = {
  path: '/vehicles',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <VehiclesV2ListView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewVehiclesV2View />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':vehicleId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditVehiclesV2View />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/vehicles" replace />
    }
  ]
}
