import { useQuery, gql } from '@apollo/client'
import { IUseLogin } from 'features/Login/types'
import { isSuperAdmin } from 'features/Users/utils'

const GET_LOGIN = gql`
  query GetLogin {
    login @client {
      accessToken {
        companyIds
        companyId
        policies
        conditions
      }
    }
  }
`

export const useLogin = (): IUseLogin => {
  const {
    data: { login }
  } = useQuery(GET_LOGIN)

  return { ...login, isSuperAdmin: isSuperAdmin(login.accessToken.conditions) }
}
