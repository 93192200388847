import { Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'
import { Navigate } from 'react-router-dom'

const PassengerTypesListView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-types-list-view" */ /* webpackPrefetch: true */ 'features/PassengerTypes/views/Index'
    )
)
const PassengerTypesNewView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-types-new-view" */ /* webpackPrefetch: true */ 'features/PassengerTypes/views/New'
    )
)

const PassengerTypesEditView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-types-edit-view" */ /* webpackPrefetch: true */ 'features/PassengerTypes/views/Edit'
    )
)
export const PassengerTypesRoutes = {
  path: '/passenger-types',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerTypesListView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerTypesNewView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':passengerTypeId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerTypesEditView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/passengers" replace />
    }
  ]
}
