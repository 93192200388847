import { Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'
import { Navigate } from 'react-router-dom'

const PassengerPropertiesListView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-properties-list-view" */ /* webpackPrefetch: true */ 'features/PassengerProperties/views/Index'
    )
)
const PassengerPropertiesNewView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-properties-new-view" */ /* webpackPrefetch: true */ 'features/PassengerProperties/views/New'
    )
)

const PassengerPropertiesEditView = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-properties-edit-view" */ /* webpackPrefetch: true */ 'features/PassengerProperties/views/Edit'
    )
)

export const PassengerPropertiesRutes = {
  path: '/passenger-properties',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerPropertiesListView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerPropertiesNewView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':passengerPropertyId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PassengerPropertiesEditView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/passenger-properties" replace />
    }
  ]
}
