import {
  getOfflinePercent,
  getOnlinePercent,
  getTotalDashboard,
  getTotalDashboardByStatus,
  getTotalTicketPayment
} from 'resolvers/bookings'

export const bookingsLocalOnlyFields = {
  BookingsDashboardData: {
    fields: {
      offlinePercent: {
        read(_, { readField }) {
          return getOfflinePercent({
            reserved: readField('reserved'),
            confirmed: readField('confirmed'),
            partiallyConfirmed: readField('partiallyConfirmed')
          })
        }
      },
      onlinePercent: {
        read(_, { readField }) {
          return getOnlinePercent({
            reserved: readField('reserved'),
            confirmed: readField('confirmed'),
            partiallyConfirmed: readField('partiallyConfirmed')
          })
        }
      },
      total: {
        read(_, { readField }) {
          return getTotalDashboard({
            reserved: readField('reserved'),
            confirmed: readField('confirmed'),
            partiallyConfirmed: readField('partiallyConfirmed')
          })
        }
      }
    }
  },
  BookingsDashboardDataMethodInfo: {
    fields: {
      total: {
        read(_, { readField }) {
          return getTotalDashboardByStatus({
            online: readField('online'),
            offline: readField('offline')
          })
        }
      }
    }
  },
  TicketPayment: {
    fields: {
      total: {
        read(_, { readField }) {
          return getTotalTicketPayment({
            subtotal: readField('subtotal'),
            vat: readField('vat'),
            discount: readField('discount')
          })
        }
      }
    }
  }
}
