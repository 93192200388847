import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BusinessSitesView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "lines" */ /* webpackPrefetch: true */ './views/Index')
)
const NewBusinessSiteView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "new-business-site" */ /* webpackPrefetch: true */ './views/New')
)
const EditBusinessSiteView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "edit-business-site" */ /* webpackPrefetch: true */ './views/Edit')
)

export const BusinessSitesRoutes = {
  path: '/business-sites',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <BusinessSitesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewBusinessSiteView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':siteId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditBusinessSiteView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/business-sites" replace />
    }
  ]
}
