import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BookingsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "bookings" */ /* webpackPrefetch: true */ './views/Index')
)
const EditBookingView = lazy(
  () => import(/* webpackChunkName: "edit-booking" */ /* webpackPrefetch: true */ './views/Edit')
)
const MoveBookingView = lazy(
  () => import(/* webpackChunkName: "move-booking" */ /* webpackPrefetch: true */ './views/Move')
)

export const BookingsRoutes = [
  {
    path: '/bookings',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <BookingsView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  },
  {
    path: '/bookings/:bookingId',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditBookingView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'move',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <MoveBookingView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/bookings" replace />
      }
    ]
  }
]
