import { Suspense, lazy } from 'react'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'

const LoginView = lazy(() => import(/* webpackChunkName: "login" */ /* webpackPrefetch: true */ './views/Index'))

export const LoginRoutes = {
  path: '/login',
  element: (
    <Suspense fallback={<PrefetchLoader />}>
      <LoginView />
    </Suspense>
  )
}
