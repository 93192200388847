export enum EAppCategories {
  ROUTES = 'routes',
  COMPANIES = 'companies',
  COMPANY_LINES = 'companyLines',
  PLACES = 'places',
  SEGMENTS = 'segments',
  PRICING = 'pricing',
  CAMPAIGNS = 'campaigns',
  LOYALTY = 'loyalty',
  TRIPS = 'trips',
  BOOKINGS = 'bookings',
  ASSISTANCE = 'assistance',
  ANCILLARIES = 'ancillaries',
  POS = 'pos',
  VEHICLES = 'vehicles',
  USERS = 'users',
  CHANGE_MANAGEMENT = 'changeManagement',
  PASSENGER_TYPES = 'passengerTypes',
  CATALOGS = 'catalogs',
  INVENTORIES = 'inventories',
  KREDITS = 'kredits',
  CLIENTS = 'clients',
  PARTNERS = 'partners'
}
