import { useQuery, gql } from '@apollo/client'
import { isEmpty } from 'lodash'
import { useRef, createContext, ReactElement, ReactNode, RefObject } from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from 'components/v2/Header'
import { Sidebar } from 'components/v2/Sidebar'
import { device } from 'utils/style'
import { useIsSidebarCollapsed } from 'variables/sidebar'

const FullHeight = styled.div`
  height: 100%;
  overflow: hidden;
`

const StyledAppContent = styled.div<{ isSidebarCollapsed?: boolean }>`
  background-color: #fafaff;
  right: 0;
  position: absolute;
  height: 100%;
  overflow: auto;
  width: calc(100% - 72px);

  @media ${device.laptop} {
    right: 0;
    ${({ isSidebarCollapsed }): string => (!isSidebarCollapsed ? 'width: calc(100% - 200px);' : '')};
  }

  @media print {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    left: 0;
  }
`

const MainContent = styled.div`
  height: calc(100% - 75px);
  width: 100%;
  position: relative;
  overflow: hidden;
`

const IS_LOGGED_IN = gql`
  query isLoggedIn {
    login @client {
      accessToken {
        value
      }
    }
  }
`

const checkLogin = ({ accessToken }: { accessToken: { value?: string } }): boolean => {
  return !isEmpty(accessToken.value)
}

interface ILayoutContext {
  layout: { appContentEl: RefObject<HTMLDivElement> | null }
}

export const LayoutContext = createContext<ILayoutContext>({
  layout: {
    appContentEl: null
  }
})

const AppContent = ({ children }: { children: ReactNode }): ReactElement => {
  const appContentEl = useRef<HTMLDivElement>(null)
  const isSidebarCollapsed = useIsSidebarCollapsed()
  const {
    data: { login: currentLogin }
  } = useQuery(IS_LOGGED_IN)

  const isLoggedIn = checkLogin(currentLogin)

  if (!isLoggedIn) {
    return <Navigate to="/login" />
  }

  return (
    <LayoutContext.Provider
      value={{
        layout: {
          appContentEl
        }
      }}
    >
      <FullHeight>
        <Sidebar />
        <Header />
        <MainContent>
          <StyledAppContent ref={appContentEl} isSidebarCollapsed={isSidebarCollapsed}>
            {children}
          </StyledAppContent>
        </MainContent>
      </FullHeight>
    </LayoutContext.Provider>
  )
}

export const Content = styled.div<{ hasMaxWidth?: boolean; hasLargeMaxWidth?: boolean }>`
  padding: 30px 20px;

  ${({ hasMaxWidth, hasLargeMaxWidth }): string => {
    if (hasMaxWidth) {
      return `
        max-width: 700px;
        margin: auto;
      `
    } else if (hasLargeMaxWidth) {
      return `
        max-width: 1320px;
        margin: auto;
      `
    }
    return ''
  }}
`

const StyledContentHeader = styled.div<{ isResponsive?: boolean }>`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  ${({ isResponsive }): string => {
    if (isResponsive) {
      return `
        flex-direction: column;

        @media ${device.laptop} {
          flex-direction: row;
          align-items: center;
        }
      `
    }

    return ``
  }}
`

export const ContentHeader = ({
  children,
  isResponsive,
  className
}: {
  className?: string
  isResponsive?: boolean
  children: ReactNode
}): ReactElement => {
  return (
    <StyledContentHeader isResponsive={isResponsive} className={className}>
      {children}
    </StyledContentHeader>
  )
}

export const ContentBody = styled.div`
  background-color: #fff;
  padding: 32px;
  border-radius: 16px;
`

export const FiltersContainer = styled.div`
  margin-bottom: 20px;
`

export const SimpleContent = styled.div`
  padding: 35px 20px;
`

export const TabsActions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: -20px;
`

export { AppContent }
