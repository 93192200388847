import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CompaniesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "companies" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCompanyView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-company" */ /* webpackPrefetch: true */ './views/New')
)
const EditCompanyView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-company" */ /* webpackPrefetch: true */ './views/Edit')
)

export const CompaniesRoutes = {
  path: '/companies',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <CompaniesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewCompanyView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':companyId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditCompanyView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/companies" replace />
    }
  ]
}
