import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const PartnersView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "partners" */ /* webpackPrefetch: true */ './views/Index')
)
const NewPartnerView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "new-partner" */ /* webpackPrefetch: true */ './views/New')
)
const EditPartnerView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "edit-partner" */ /* webpackPrefetch: true */ './views/Edit')
)

export const PartnersRoutes = {
  path: '/partners',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <PartnersView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewPartnerView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':partnerId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditPartnerView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/partners" replace />
    }
  ]
}
