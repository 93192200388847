import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const LoyaltyNewRewardView = lazy(
  () =>
    import(/* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/NewReward')
)
const LoyaltyRewardsView = lazy(
  () => import(/* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/Rewards')
)

const LoyaltyCampaignRulesView = lazy(
  () => import(/* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/Rules')
)
const LoyaltyCampaignNewRuleView = lazy(
  () => import(/* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/NewRule')
)
const LoyaltyCampaignNewRuleEdit = lazy(
  () =>
    import(/* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/EditRule')
)

const LoyaltyCampaignsView = lazy(
  () =>
    import(
      /* webpackChunkName: "loyalty-campaigns" */ /* webpackPrefetch: true */ 'features/Loyalty/views/LoyaltyCampaigns'
    )
)

const NewCampaignView = lazy(
  () =>
    import(
      /* webpackChunkName: "loyalty-new-campaign" */ /* webpackPrefetch: true */ 'features/Loyalty/views/NewLoyaltyCampaign'
    )
)

const EditLoyaltyCampaignView = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-loyalty-campaign" */ /* webpackPrefetch: true */ 'features/Loyalty/views/EditLoyaltyCampaign'
    )
)

export const LoyaltyRoutes = [
  {
    path: '/loyalty',
    children: [
      {
        index: true,
        element: <Navigate to="/loyalty/campaigns" replace />
      }
    ]
  },
  {
    path: '/loyalty/campaigns',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyCampaignsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewCampaignView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':campaignId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditLoyaltyCampaignView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/loyalty/campaigns" replace />
      }
    ]
  },
  {
    path: '/loyalty/campaigns/:campaignId/rules',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyCampaignRulesView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyCampaignNewRuleView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':ruleId/edit',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyCampaignNewRuleEdit />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/loyalty/campaigns" replace />
      }
    ]
  },
  {
    path: '/loyalty/rewards',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyRewardsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <LoyaltyNewRewardView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/loyalty/rewards" replace />
      }
    ]
  }
]
