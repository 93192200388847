import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const ProductsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "snack-products" */ /* webpackPrefetch: true */ './views/Products')
)
const NewProductView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-snack-product" */ /* webpackPrefetch: true */ './views/NewProduct')
)
const EditProductView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-snack-product" */ /* webpackPrefetch: true */ './views/EditProduct')
)
const CombosView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "snack-combos" */ /* webpackPrefetch: true */ './views/Combos')
)
const NewComboView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-snack-combo" */ /* webpackPrefetch: true */ './views/NewCombo')
)
const EditComboView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-snack-combo" */ /* webpackPrefetch: true */ './views/EditCombo')
)
const PlansView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "snack-plans" */ /* webpackPrefetch: true */ './views/Plans')
)
const NewPlanView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-snack-plan" */ /* webpackPrefetch: true */ './views/NewPlan')
)
const EditPlanView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-snack-plan" */ /* webpackPrefetch: true */ './views/EditPlan')
)

export const SnacksRoutes = [
  {
    path: '/snacks',
    children: [
      {
        index: true,
        element: <Navigate to="/snacks/products" replace />
      }
    ]
  },
  {
    path: '/snacks/products',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <ProductsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewProductView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':productId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditProductView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/snacks/products" replace />
      }
    ]
  },
  {
    path: '/snacks/combos',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CombosView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewComboView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':comboId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditComboView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/snacks/combos" replace />
      }
    ]
  },
  {
    path: '/snacks/plans',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <PlansView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewPlanView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':planItemId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditPlanView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/snacks/plans" replace />
      }
    ]
  }
]
