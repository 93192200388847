import { makeVar, useReactiveVar } from '@apollo/client'

const isSidebarCollapsedVar = makeVar(localStorage.getItem('sidebarCollapsed') === 'true' || false)
export const isSidebarCollapsed = isSidebarCollapsedVar()
export const useIsSidebarCollapsed = (): boolean => {
  const isSidebarCollapsed = useReactiveVar(isSidebarCollapsedVar)
  return isSidebarCollapsed
}
export const setIsSidebarCollapsed = (isSidebarCollapsed: boolean): void => {
  isSidebarCollapsedVar(isSidebarCollapsed)
}

export const saveSidebarStateOnLocalStorage = (isCollapsed: boolean): void => {
  try {
    localStorage?.setItem('sidebarCollapsed', String(isCollapsed))
  } catch (ex) {
    // Do nothing
  }
}
