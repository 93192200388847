import { ReactElement } from 'react'
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import { ModalProvider } from 'components/v2/Modal'
import { FeatureFlagsModal } from 'components/FeatureFlagsModal'
import { SnackbarProvider } from 'components/Snackbar'
import { LoginRoutes } from 'features/Login/routes'
import { RoutesRoutes } from 'features/Routes/routes'
import { CompaniesRoutes } from 'features/Companies/routes'
import { LinesRoutes } from 'features/Lines/routes'
import { CitiesRoutes } from 'features/Cities/routes'
import { PlacesRoutes } from 'features/Places/routes'
import { SegmentsRoutes } from 'features/Segments/routes'
import { ServicesRoutes } from 'features/Services/routes'
import { TripsRoutes } from 'features/Trips/routes'
import { BookingsRoutes } from 'features/Bookings/routes'
import { UsersRoutes } from 'features/Users/routes'
import { RolesRoutes } from 'features/Roles/routes'
import { CompanyRolesRoutes } from 'features/CompanyRoles/routes'
import { SitesRoutes } from 'features/Sites/routes'
import { VehicleTemplatesRoutes } from 'features/VehicleTemplates/routes'
import { BookingClassesRoutes } from 'features/BookingClasses/routes'
import { SeasonEventsRoutes } from 'features/SeasonEvents/routes'
import { FareFamiliesRoutes } from 'features/FareFamilies/routes'
import { FareRulesRoutes } from 'features/FareRules/routes'
import { InventoryRulesRoutes } from 'features/InventoryRules/routes'
import { CampaignsRoutes } from 'features/Campaigns/routes'
import { CouponsRoutes } from 'features/Coupons/routes'
import { ReportsRoutes } from 'features/Reports/routes'
import { ChangesRoutes } from 'features/Changes/routes'
import { SnacksRoutes } from 'features/Snacks/routes'
import { InventoriesRoutes } from 'features/Inventories/routes'
import { DrStrangeRoutes } from 'features/DrStrange/routes'
import { CartsRoutes } from 'features/Carts/routes'
import { AncillariesRoutes } from 'features/Ancillaries/routes'
import { OrdersRoutes } from 'features/Orders/routes'
import { SalesRoutes } from 'features/Sales/routes'
import { WastesRoutes } from 'features/Wastes/routes'
import { LoyaltyRoutes } from 'features/Loyalty/routes'
import { PassengerTypesRoutes } from 'features/PassengerTypes/routes'
import { PassengerPropertiesRutes } from 'features/PassengerProperties/routes'
import { SubcampaignRoutes } from 'features/Subcampaigns/routes'
import { KreditsRoutes } from 'features/Kredits/routes'
import { EBuildEnv, isLocalOrStagingBuild } from 'config/constants'
import { FeatureFlagsProvider } from '@10xlabs/utils'
import { featureFlagsValues } from 'config/feature-flags'
import { BusinessClientsRoutes } from 'features/BusinessClients/routes'
import { BusinessSitesRoutes } from 'features/BusinessSites/routes'
import { PartnersRoutes } from 'features/Partners/routes'
import { VehiclesV2Routes } from 'features/Vehicles/routes'
import { DrawerProvider } from 'components/v2/Drawer'
import { BusinessCustomerRoutes } from 'features/BusinessCustomer/routes'
import { DriversRoutes } from 'features/Drivers/routes'
import { PartnerCostRoutes } from 'features/PartnerCost/routes'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    font-family: "Roboto", sans-serif;
    color: #5d5c70;
    font-size: 14px;
  }

  #app-root {
    height: 100%;

  }

  ::placeholder {
    color: #cacad2;
  }
`

const router = createBrowserRouter([
  LoginRoutes,
  RoutesRoutes,
  CompaniesRoutes,
  LinesRoutes,
  CitiesRoutes,
  PlacesRoutes,
  SegmentsRoutes,
  ServicesRoutes,
  ...TripsRoutes,
  ...BookingsRoutes,
  UsersRoutes,
  RolesRoutes,
  CompanyRolesRoutes,
  ...SitesRoutes,
  VehicleTemplatesRoutes,
  BookingClassesRoutes,
  SeasonEventsRoutes,
  FareFamiliesRoutes,
  FareRulesRoutes,
  InventoryRulesRoutes,
  CampaignsRoutes,
  ...CouponsRoutes,
  ReportsRoutes,
  ChangesRoutes,
  ...SnacksRoutes,
  ...InventoriesRoutes,
  DrStrangeRoutes,
  ...CartsRoutes,
  AncillariesRoutes,
  OrdersRoutes,
  SalesRoutes,
  WastesRoutes,
  ...LoyaltyRoutes,
  PassengerTypesRoutes,
  PassengerPropertiesRutes,
  ...SubcampaignRoutes,
  ...KreditsRoutes,
  ...BusinessClientsRoutes,
  ...BusinessCustomerRoutes,
  BusinessSitesRoutes,
  PartnersRoutes,
  VehiclesV2Routes,
  DriversRoutes,
  PartnerCostRoutes,
  {
    path: '*',
    element: <Navigate to="/routes" replace />
  }
])

const App = (): ReactElement => {
  return (
    <FeatureFlagsProvider
      featureFlagsValues={featureFlagsValues}
      buildEnv={process.env.ENVIRONMENT_BUILD as EBuildEnv.Local | EBuildEnv.Staging}
    >
      <ModalProvider>
        <DrawerProvider>
          <SnackbarProvider>
            <GlobalStyle />
            {isLocalOrStagingBuild && <FeatureFlagsModal />}
            <RouterProvider router={router} />
          </SnackbarProvider>
        </DrawerProvider>
      </ModalProvider>
    </FeatureFlagsProvider>
  )
}

export default App
