import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const RoutesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "routes" */ /* webpackPrefetch: true */ './views/Index')
)
const NewRouteView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-route" */ /* webpackPrefetch: true */ './views/New')
)
const EditRouteView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-route" */ /* webpackPrefetch: true */ './views/Edit')
)

export const RoutesRoutes = {
  path: '/routes',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <RoutesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewRouteView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':routeId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditRouteView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/routes" replace />
    }
  ]
}
