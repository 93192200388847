import { ReactElement } from 'react'
import styled from 'styled-components'

import { IIconProps } from './types'

const Svg = styled.svg`
  fill: #b0b0b0;
  height: 24px;
  width: 24px;
`

export const WalletIcon = ({ className }: IIconProps): ReactElement => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_754_209)">
        <path d="M19.2105 16.8421V17.6316C19.2105 18.5 18.5 19.2105 17.6316 19.2105H6.57895C5.70263 19.2105 5 18.5 5 17.6316V6.57895C5 5.71053 5.70263 5 6.57895 5H17.6316C18.5 5 19.2105 5.71053 19.2105 6.57895V7.36842H12.1053C11.2289 7.36842 10.5263 8.07895 10.5263 8.94737V15.2632C10.5263 16.1316 11.2289 16.8421 12.1053 16.8421H19.2105ZM12.1053 15.2632H20V8.94737H12.1053V15.2632ZM15.2632 13.2895C14.6079 13.2895 14.0789 12.7605 14.0789 12.1053C14.0789 11.45 14.6079 10.9211 15.2632 10.9211C15.9184 10.9211 16.4474 11.45 16.4474 12.1053C16.4474 12.7605 15.9184 13.2895 15.2632 13.2895Z" />
      </g>
      <defs>
        <clipPath id="clip0_754_209">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </Svg>
  )
}
