import { ApolloCache, gql } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import { initCacheData, wsClient } from 'gql/client'
import { IJWTAccessToken, IJWTRefreshToken, ILogin, ILoginQuery } from 'features/Login/types'
import { client } from 'gql/client'
import { GET_URLS } from 'features/App/queries-legacy'

const GET_LOGIN = gql`
  query GetLogin {
    login @client {
      accessToken {
        policies
        conditions
        companyIds
        companyId
        expirationDate
        value
      }
      refreshToken {
        expirationDate
        value
      }
    }
  }
`

export const resolvers = {
  Mutation: {
    setTokens(_root: unknown, { accessToken, refreshToken }: ILogin, { cache }: { cache: ApolloCache<ILogin> }): null {
      const { login } = cache.readQuery({ query: GET_LOGIN }) as { login: ILoginQuery }

      try {
        const { pol, cds, com, exp } = jwtDecode(accessToken) as IJWTAccessToken
        const { exp: refreshTokenExpiration } = jwtDecode(refreshToken) as IJWTRefreshToken

        cache.writeQuery({
          query: GET_LOGIN,
          data: {
            login: {
              ...login,
              accessToken: {
                ...login.accessToken,
                policies: pol || [],
                conditions: cds || [],
                companyIds: com || [],
                companyId: com?.at(0) || '',
                expirationDate: exp,
                value: accessToken
              },
              refreshToken: {
                ...login.refreshToken,
                expirationDate: refreshTokenExpiration,
                value: refreshToken
              }
            }
          }
        })

        try {
          localStorage.setItem('nexbus_at', accessToken)
          localStorage.setItem('nexbus_rt', refreshToken)
        } catch (ex) {
          /* empty */
        }
      } catch (ex) {
        /* empty */
      }
      return null
    },
    removeTokens(_root: unknown, variables: unknown, { cache }: { cache: ApolloCache<ILoginQuery> }): null {
      const { login } = cache.readQuery({ query: GET_LOGIN }) as { login: ILoginQuery }
      const { urls } = cache.readQuery({ query: GET_URLS }) as { urls: { last: string } }

      cache.writeQuery({
        query: GET_LOGIN,
        data: {
          login: {
            ...login,
            accessToken: {
              ...login.accessToken,
              policies: [],
              conditions: [],
              companyIds: [],
              companyId: '',
              expirationDate: null,
              value: null
            },
            refreshToken: {
              ...login.refreshToken,
              expirationDate: null,
              value: null
            }
          }
        }
      })

      try {
        wsClient.terminate()
      } catch (ex) {
        /* empty */
      }

      try {
        localStorage.removeItem('nexbus_at')
        localStorage.removeItem('nexbus_rt')
        client.cache.reset()
        initCacheData({
          lastUrl: urls.last
        })
      } catch (ex) {
        /* empty */
      }

      return null
    }
  }
}
