import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const SeasonEventsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "season-events" */ /* webpackPrefetch: true */ './views/Index')
)
const NewSeasonEventView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-season-event" */ /* webpackPrefetch: true */ './views/New')
)
const EditSeasonEventView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-season-event" */ /* webpackPrefetch: true */ './views/Edit')
)

export const SeasonEventsRoutes = {
  path: '/season-events',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <SeasonEventsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewSeasonEventView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':seasonEventId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditSeasonEventView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/season-events" replace />
    }
  ]
}
