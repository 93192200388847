import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CitiesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "cities" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCityView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-city" */ /* webpackPrefetch: true */ './views/New')
)
const EditCityView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-city" */ /* webpackPrefetch: true */ './views/Edit')
)

export const CitiesRoutes = {
  path: '/cities',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <CitiesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewCityView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':cityId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditCityView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/cities" replace />
    }
  ]
}
