import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CartsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "carts" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCartView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-cart" */ /* webpackPrefetch: true */ './views/New')
)
const EditCartView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-cart" */ /* webpackPrefetch: true */ './views/Edit')
)
const CartItemsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "cart-items" */ /* webpackPrefetch: true */ './views/CartItems')
)
const CartInboundOrdersView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "cart-inbound-orders" */ /* webpackPrefetch: true */ './views/InboundOrders')
)
const CartOutboundOrdersView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "cart-outbound-orders" */ /* webpackPrefetch: true */ './views/OutboundOrders')
)
const CartPendingOrdersView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "cart-pending-orders" */ /* webpackPrefetch: true */ './views/PendingOrders')
)
const CartWastesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "cart-wastes" */ /* webpackPrefetch: true */ './views/Wastes')
)
const ReceiveOrderView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "receive-order-from-cart" */ /* webpackPrefetch: true */ './views/ReceiveOrder')
)
const RemoveBulkItemsView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "cart-remove-bulk-items" */ /* webpackPrefetch: true */ './views/RemoveBulkItems')
)
const CartActivityView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "cart-activity" */ /* webpackPrefetch: true */ './views/Activity')
)
const EditOrderView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-cart-order" */ /* webpackPrefetch: true */ './views/EditOrder')
)

export const CartsRoutes = [
  {
    path: '/carts',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewCartView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  },
  {
    path: '/carts/:cartId',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditCartView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'items',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartItemsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'inbound-orders',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartInboundOrdersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'outbound-orders',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartOutboundOrdersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'pending-orders',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartPendingOrdersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'activity',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartActivityView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'remove-bulk-items',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <RemoveBulkItemsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'wastes',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CartWastesView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/carts" replace />
      }
    ]
  },
  {
    path: '/carts/:cartId/orders',
    children: [
      {
        path: ':orderId',
        children: [
          {
            index: true,
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <EditOrderView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: 'receive',
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <ReceiveOrderView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: '*',
            element: <Navigate to="/carts" replace />
          }
        ]
      }
    ]
  }
]
