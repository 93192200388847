import { createContext, useRef, useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

import { device } from 'utils/style'
// @ts-expect-error TS(2307)
const Context = createContext()

const SnackbarProviderContainer = styled.div<TSFixMe>`
  z-index: 0;
  height: 100%;
`

export const SnackbarProvider = ({ children }: TSFixMe): TSFixMe => {
  const modalRef = useRef()
  const [context, setContext] = useState()

  useEffect((): TSFixMe => {
    setContext(modalRef.current)
  }, [])

  return (
    <SnackbarProviderContainer>
      <Context.Provider value={context}>{children}</Context.Provider>

      {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div ref={modalRef} />
    </SnackbarProviderContainer>
  )
}

const Dialog = styled.div<TSFixMe>`
  background: #323232;
  border-radius: 5px;
  box-shadow: 0 7px 7px 0 rgba(110, 136, 181, 0.2);
  position: fixed;
  bottom: 30px;
  left: 0px;
  z-index: 100000;
  padding: 10px 15px;
  margin: 0 10px;
  transition: all 0.3s ease-in;

  ${({ state }): TSFixMe => {
    switch (state) {
      case 'entered':
        return `
          opacity: 1;
          transform: translate(0, 0%);
        `
      case 'entering':
        return `
          opacity: 0.1;
          transform: translate(0, 100%);
        `
      case 'exited':
        return `
          display: none;
        `
      case 'exiting':
        return `
          display: none;
        `
    }
  }}

  @media ${device.laptop} {
    left: 20px;
    bottom: 50px;
  }
`

const Container = ({ children, ...props }: TSFixMe): TSFixMe => {
  const modalNode = useContext(Context)

  return modalNode
    ? ReactDOM.createPortal(
        <Transition appear in timeout={0}>
          {(state: TSFixMe): TSFixMe => {
            return (
              <Dialog state={state} {...props}>
                {children}
              </Dialog>
            )
          }}
        </Transition>,
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        modalNode
      )
    : null
}

const SnackbarAction = styled.div<TSFixMe>`
  text-transform: uppercase;
  color: #aa83fd;
  cursor: pointer;
  float: right;
  display: inline-block;
  margin: 5px 0 0 30px;

  @media ${device.laptop} {
    font-size: 14px;
  }
`

const Action = ({ children, ...props }: TSFixMe): TSFixMe => {
  return <SnackbarAction {...props}>{children}</SnackbarAction>
}

const SnackbarContent = styled.div<TSFixMe>`
  color: #ffffff;
  display: inline-block;
  margin: 5px 0;

  @media ${device.laptop} {
    font-size: 14px;
  }
`

const Content = ({ children, props }: TSFixMe): TSFixMe => {
  return <SnackbarContent {...props}>{children}</SnackbarContent>
}

export default {
  Container,
  Content,
  Action
}
