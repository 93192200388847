import { ReactElement, useRef, useEffect, MutableRefObject, ReactNode } from 'react'

interface IUseClickAwayListener {
  onClickAway: () => void
  wrapper: MutableRefObject<HTMLDivElement | null>
}

const useClickAwayListener = ({ onClickAway, wrapper }: IUseClickAwayListener): void => {
  useEffect(() => {
    const handleClickAway = ({ target }: MouseEvent): void => {
      if (wrapper.current && !wrapper.current.contains(target as Node)) {
        onClickAway()
      }
    }

    document.addEventListener('mousedown', handleClickAway)

    return (): void => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])
}

interface IClickAwayListenerProps {
  onClickAway: () => void
  children: ReactNode
}

export const ClickAwayListener = ({ onClickAway, children }: IClickAwayListenerProps): ReactElement => {
  const wrapper = useRef<HTMLDivElement | null>(null)
  useClickAwayListener({ wrapper, onClickAway })

  return <div ref={wrapper}>{children}</div>
}
