import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const SegmentsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "segments" */ /* webpackPrefetch: true */ './views/Index')
)
const EditSegmentView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-segment" */ /* webpackPrefetch: true */ './views/Edit')
)

export const SegmentsRoutes = {
  path: '/segments',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <SegmentsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':segmentId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditSegmentView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/segments" replace />
    }
  ]
}
