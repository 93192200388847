export const TICKET_INSURANCE_STATUS = {
  RESERVED: 'reserved',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  CREATION_FAILED: 'creationFailed',
  CANCELLATION_FAILED: 'cancellationFailed',
  EDIT_FAILED: 'editFailed',
  FAILED: 'failed',
  PARTIALLY_CANCELLED: 'partiallyCancelled'
}
