import { ReactElement } from 'react'
import styled from 'styled-components'

import Heading from 'components/v2/Heading'
import { ISwitchProps, ISwitchWithLabelProps } from './types'

const SwitchContainer = styled.div<{ value?: boolean }>`
  align-items: center;
  background-color: ${({ value }): string => (value ? '#916EE8' : '#E3E3E3')};
  border: solid 1.1px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  display: inline-flex;
  height: 24px;
  width: 40px;
`

const Button = styled.div<{ value?: boolean; disabled?: boolean }>`
  background-color: #ffffff;
  border-radius: 50%;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  transform: ${({ value }): string => (value ? 'translateX(18px)' : 'translateX(5px)')};
  transition: 0.2s;
  height: 18px;
  width: 18px;
`

const SwitchWithLabelContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

const Switch = ({ className, value, onChange, disabled }: ISwitchProps): ReactElement => {
  return (
    <SwitchContainer className={className} value={value}>
      <Button
        onClick={(): void => {
          if (!disabled) {
            onChange(!value)
          }
        }}
        value={value}
      />
    </SwitchContainer>
  )
}

export const SwitchWithLabel = ({ label, ...props }: ISwitchWithLabelProps): ReactElement => {
  return (
    <SwitchWithLabelContainer>
      {label ? <Heading.Label>{label}</Heading.Label> : null}
      <Switch {...props} />
    </SwitchWithLabelContainer>
  )
}

export { Switch }
