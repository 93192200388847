import { ApolloCache, gql } from '@apollo/client'
import { isEmpty } from 'lodash'

import { TICKET_INSURANCE_STATUS } from 'features/Bookings/components/TicketInsuranceStatus/constants'
import { isTicketCancelled } from 'resolvers/bookings'
import { has } from 'utils/array'
import { ITicket } from 'features/Bookings/types'
import { ITicketInsurance } from 'features/Ancillaries/types'
import {
  GTAncillaryStatus,
  GTBookingV2Ticket,
  GTBookingV2TicketAncillary,
  GTBookingV2TicketAncillaryStatus,
  GTBookingV2TicketAncillaryType
} from 'gql/types.generated'

const CANCELLABLE_STATUS = [
  GTAncillaryStatus.Confirmed,
  GTAncillaryStatus.Reserved,
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed
]

const FAILED_STATUS = [
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed,
  GTAncillaryStatus.Failed
]

const IS_RESERVED_STATUS = [
  GTAncillaryStatus.Confirmed,
  GTAncillaryStatus.Reserved,
  GTAncillaryStatus.CreationFailed,
  GTAncillaryStatus.CancellationFailed,
  GTAncillaryStatus.UpdateFailed
]

const APPLICABLE_STATUS = [GTAncillaryStatus.Cancelled]

export const canBeCancelled = (ticketInsuranceStatus: string): boolean => {
  return has(CANCELLABLE_STATUS, ticketInsuranceStatus.toLowerCase())
}

export const canBeApplied = (ticketInsuranceStatus: string, insuranceIsPaid: boolean): boolean => {
  return has(APPLICABLE_STATUS, ticketInsuranceStatus.toLowerCase()) && !insuranceIsPaid
}

export const isAncillaryReserved = (ticketInsuranceStatus: string): boolean => {
  return !ticketInsuranceStatus || has(IS_RESERVED_STATUS, ticketInsuranceStatus.toLowerCase())
}

export const getInsuranceByTicket = (ticket: GTBookingV2Ticket): GTBookingV2TicketAncillary | undefined => {
  const ancillaries = ticket.ancillaries || []
  return ancillaries.find(({ type }) => type === GTBookingV2TicketAncillaryType.Insurance)
}

export const getInsuranceTotalByTicket = (ticket: GTBookingV2Ticket): number => {
  if (!isTicketCancelled(ticket)) {
    const insurance = getInsuranceByTicket(ticket)
    if (insurance && isAncillaryReserved(insurance.status)) {
      return insurance.total
    }
  }

  return 0
}

export const getTotalInsuranceByTickets = (tickets: GTBookingV2Ticket[]): number => {
  let total = 0

  tickets.forEach((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const insurance = getInsuranceByTicket(ticket)

      if (insurance && isAncillaryReserved(insurance.status)) {
        total += insurance.total
      }
    }
  })

  return total
}

export const getTicketsToApplyInsurance = (tickets: ITicket[]): ITicket[] => {
  return tickets.filter((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === GTBookingV2TicketAncillaryType.Insurance)
      return !insurance || canBeApplied(insurance.status, false)
    }

    return false
  })
}

export const getTicketsToCancelInsurance = (tickets: ITicket[]): ITicket[] => {
  return tickets.filter((ticket): TSFixMe => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }): TSFixMe => type === GTBookingV2TicketAncillaryType.Insurance)
      return insurance && canBeCancelled(insurance.status)
    }

    return false
  })
}

const mapBookingV2StatusToTicketInsuranceStatus = (status?: GTBookingV2TicketAncillaryStatus): string | undefined => {
  if (!status) {
    return undefined
  }

  switch (status) {
    case GTBookingV2TicketAncillaryStatus.Confirmed:
      return GTAncillaryStatus.Confirmed
    case GTBookingV2TicketAncillaryStatus.Reserved:
      return GTAncillaryStatus.Reserved
    case GTBookingV2TicketAncillaryStatus.Cancelled:
      return GTAncillaryStatus.Cancelled
    case GTBookingV2TicketAncillaryStatus.CreationFailed:
      return GTAncillaryStatus.CreationFailed
    case GTBookingV2TicketAncillaryStatus.CancellationFailed:
      return GTAncillaryStatus.CancellationFailed
    case GTBookingV2TicketAncillaryStatus.UpdateFailed:
      return GTAncillaryStatus.UpdateFailed
  }
}

export const getStatusByTickets = (tickets: GTBookingV2Ticket[]): string | null => {
  return tickets.reduce((result, ticket): TSFixMe => {
    if (isTicketCancelled(ticket)) {
      return result
    }

    const insurance = getInsuranceByTicket(ticket)
    const insuranceStatus = mapBookingV2StatusToTicketInsuranceStatus(insurance?.status)

    if (!insurance || insuranceStatus === result) {
      return result
    }

    if (has(FAILED_STATUS, result ?? '') || has(FAILED_STATUS, insuranceStatus)) {
      return TICKET_INSURANCE_STATUS.FAILED
    }

    if (!result) {
      return insuranceStatus
    }

    if (result === TICKET_INSURANCE_STATUS.PARTIALLY_CANCELLED) {
      return result
    }

    if (
      (result === TICKET_INSURANCE_STATUS.CANCELLED && insuranceStatus !== result) ||
      (insuranceStatus === TICKET_INSURANCE_STATUS.CANCELLED && result !== insuranceStatus)
    ) {
      return TICKET_INSURANCE_STATUS.PARTIALLY_CANCELLED
    }

    if (
      (result === TICKET_INSURANCE_STATUS.CONFIRMED && insuranceStatus !== result) ||
      (insuranceStatus === TICKET_INSURANCE_STATUS.CONFIRMED && result !== insuranceStatus)
    ) {
      return TICKET_INSURANCE_STATUS.CONFIRMED
    }

    return result
  }, null)
}

const INSURANCE_FRAGMENT = gql`
  fragment TicketInsuranceStatus on TicketInsurance {
    insurance {
      status
      confirmedAt
      externalID
      certificateID
      insuranceCoverage {
        departureAt
        arrivalAt
        passenger {
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`

const BOOKING_TICKET_INSURANCE_FRAGMENT = gql`
  fragment BookingTicketInsurance on BookingTicket {
    ancillaries {
      paid
      type
      subtotal
      vat
      status
    }
  }
`

export const resolvers = {
  Mutation: {
    applyTicketInsurance(
      _root: unknown,
      { ticketInsuranceId }: { ticketInsuranceId: string },
      { cache }: { cache: ApolloCache<{ ticketInsuranceId: string }> }
    ): null {
      const fragment = cache.readFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT
      })

      const ticketInsurance = fragment as ITicketInsurance
      cache.writeFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT,
        data: {
          insurance: {
            ...ticketInsurance.insurance,
            status: TICKET_INSURANCE_STATUS.RESERVED
          },
          __typename: 'TicketInsurance'
        }
      })

      return null
    },
    applyTicketsInsurance(
      _root: unknown,
      { ticketIds }: { ticketIds: string[] },
      { cache }: { cache: ApolloCache<{ ticketIds: string[] }> }
    ): null {
      ticketIds.forEach((ticketId): TSFixMe => {
        let newAncillaries
        const fragment =
          cache.readFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT
          }) ?? []

        const ticket = fragment as ITicket
        const ancillaries = ticket.ancillaries || []

        const insurance = ancillaries.find(({ type }): TSFixMe => type === GTBookingV2TicketAncillaryType.Insurance)

        if (!isEmpty(insurance)) {
          newAncillaries = ancillaries.map((ancillary): TSFixMe => {
            if (ancillary.type === GTBookingV2TicketAncillaryType.Insurance) {
              return {
                ...ancillary,
                status: TICKET_INSURANCE_STATUS.RESERVED
              }
            } else {
              return ancillary
            }
          })
        } else {
          newAncillaries = [
            ...ancillaries,
            {
              status: TICKET_INSURANCE_STATUS.RESERVED,
              subtotal: 0,
              vat: 0,
              type: GTBookingV2TicketAncillaryType.Insurance
            }
          ]
        }

        cache.writeFragment({
          id: cache.identify({
            __typename: 'BookingTicket',
            id: ticketId
          }),
          fragment: BOOKING_TICKET_INSURANCE_FRAGMENT,
          data: {
            ancillaries: newAncillaries,
            __typename: 'BookingTicket'
          }
        })
      })

      return null
    },
    cancelTicketInsurance(
      _root: unknown,
      { ticketInsuranceId }: { ticketInsuranceId: string },
      { cache }: { cache: ApolloCache<{ ticketInsuranceId: string }> }
    ): null {
      const fragment = cache.readFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT
      })

      const ticketInsurance = fragment as ITicketInsurance
      cache.writeFragment({
        id: cache.identify({
          __typename: 'TicketInsurance',
          id: ticketInsuranceId
        }),
        fragment: INSURANCE_FRAGMENT,
        data: {
          __typename: 'TicketInsurance',
          insurance: {
            ...ticketInsurance.insurance,
            status: TICKET_INSURANCE_STATUS.CANCELLED
          }
        }
      })

      return null
    },
    cancelTicketsInsurance(
      _root: unknown,
      { ticketIds, status }: { ticketIds: string[]; status: string },
      { cache }: { cache: ApolloCache<{ ticketIds: string[]; status: string }> }
    ): null {
      status = status || TICKET_INSURANCE_STATUS.CANCELLED

      ticketIds.forEach((ticketId): TSFixMe => {
        let newAncillaries
        const fragment =
          cache.readFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT
          }) || []

        const ticket = fragment as ITicket
        const ancillaries = ticket.ancillaries || []

        const insurance = ancillaries.find(({ type }): TSFixMe => type === GTBookingV2TicketAncillaryType.Insurance)

        if (!isEmpty(insurance) && canBeCancelled(insurance.status)) {
          newAncillaries = ancillaries.map((ancillary): TSFixMe => {
            if (ancillary.type === GTBookingV2TicketAncillaryType.Insurance) {
              return {
                ...ancillary,
                status
              }
            } else {
              return ancillary
            }
          })

          cache.writeFragment({
            id: cache.identify({
              __typename: 'BookingTicket',
              id: ticketId
            }),
            fragment: BOOKING_TICKET_INSURANCE_FRAGMENT,
            data: {
              ancillaries: newAncillaries,
              __typename: 'BookingTicket'
            }
          })
        }
      })

      return null
    }
  }
}
