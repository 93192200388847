import { ReactElement, Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BusinessClientsListView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "BusinessClientsListView" */ /* webpackPrefetch: true */ './views/Index')
)
const NewBusinessClientView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "NewBusinessClientView" */ /* webpackPrefetch: true */ './views/New')
)
const EditBusinessClientView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "EditBusinessClientView" */ /* webpackPrefetch: true */ './views/Edit')
)

export const BusinessClientsRoutes = [
  {
    path: '/business-clients',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <BusinessClientsListView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':clientId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditBusinessClientView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewBusinessClientView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  }
]
