import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const NewServiceView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-service" */ /* webpackPrefetch: true */ './views/New')
)
const EditServiceView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-service" */ /* webpackPrefetch: true */ './views/Edit')
)

export const ServicesRoutes = {
  path: '/services',
  children: [
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewServiceView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':serviceId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditServiceView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/routes" replace />
    }
  ]
}
