import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CompanyRolesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "company-roles" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCompanyRoleView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-company-role" */ /* webpackPrefetch: true */ './views/New')
)
const EditCompanyRoleView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-company-role" */ /* webpackPrefetch: true */ './views/Edit')
)

export const CompanyRolesRoutes = {
  path: '/company-roles',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <CompanyRolesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewCompanyRoleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':companyRoleId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditCompanyRoleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/company-roles" replace />
    }
  ]
}
