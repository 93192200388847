import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const WastesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "orders" */ /* webpackPrefetch: true */ './views/Index')
)

export const WastesRoutes = {
  path: '/wastes',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <WastesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/wastes" replace />
    }
  ]
}
