import { ReactElement } from 'react'
import styled from 'styled-components'

import { IIconProps } from './types'

const Svg = styled.svg`
  fill: #cacad2;
  width: 24px;
  height: 24px;
`

export const RewardsIcon = ({ className }: IIconProps): ReactElement => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" className={className}>
      <path d="M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z" />
    </Svg>
  )
}
