import { ApolloError } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'

export const isNotFoundError = (error: ApolloError | undefined): boolean => {
  return getStatusCode(error) === 404
}

export const isInternalError = (error: ApolloError | undefined): boolean => {
  return getStatusCode(error) >= 500
}

export const isConflictError = (error: ApolloError | undefined): boolean => {
  return getStatusCode(error) === 409
}

export const isUnprocessableError = (error: ApolloError | undefined): boolean => {
  return getStatusCode(error) === 422
}

export const getUnprocessableErrors = (error: ApolloError | undefined): object[] => {
  if (!error) return []

  const { graphQLErrors, networkError } = error
  if (networkError) {
    return []
  } else if (graphQLErrors?.length > 0) {
    const firstError = graphQLErrors[0]

    if (firstError.extensions) {
      const { statusCode, errors = [] } = firstError.extensions

      if (statusCode === 422) {
        return errors || []
      }
    }
  }

  return []
}

interface INetworkError extends Error {
  statusCode: number
}

export const getStatusCode = (error: ApolloError | ErrorResponse | undefined): number => {
  if (!error) return 500

  const { graphQLErrors, networkError } = error

  if (networkError) {
    return (networkError as INetworkError)?.statusCode
  } else if (graphQLErrors && graphQLErrors?.length > 0) {
    const firstError = graphQLErrors[0]

    if (firstError.extensions) {
      const { statusCode } = firstError.extensions
      return statusCode
    }
  }

  return 500
}

export const isInStockError = ({ field = '', code = '' }): boolean => {
  return field === 'quantity' && (code === 'gte' || code === 'lte')
}

export const isNotSameVehicleCompany = ({ field = '', code = '' }): boolean => {
  return field === 'vehicleSchedule.vehicle.CompanyID' && code === 'eq'
}

export const getFirstCode = ({ graphQLErrors }: ErrorResponse | ApolloError): string => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const firstError = graphQLErrors[0]

    if (firstError.extensions) {
      return firstError.extensions.code
    }
  }

  return ''
}

enum ErrorCodes {
  MISMATCH_ROUTE_SEGMENTS = 'mismatch_route_segments',
  ACCOUNTS_CONFLICT = 'accounts_conflict',
  USER_BLOCKED = 'user_blocked'
}

export const isMismatchRouteSegmentsError = (error: ApolloError): boolean => {
  return getFirstCode(error) === ErrorCodes.MISMATCH_ROUTE_SEGMENTS
}

export const isAccountsConflictError = (error: ApolloError): boolean => {
  return getFirstCode(error) === ErrorCodes.ACCOUNTS_CONFLICT
}

export const isUniqueUsernameError = ({ field = '', code = '' }): boolean => {
  return field === 'username' && code === 'unique'
}

export const isUserBlockedError = (error: ApolloError): boolean => {
  return getFirstCode(error) === ErrorCodes.USER_BLOCKED
}

export const isPasswordConflictError = (error: ApolloError): boolean => {
  return error.graphQLErrors[0]?.extensions?.errors[0]?.code === 'unique'
}
