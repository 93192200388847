import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const ReportsView = lazy(() => import(/* webpackChunkName: "reports" */ /* webpackPrefetch: true */ './views/Index'))

export const ReportsRoutes = {
  path: '/reports',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <ReportsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/reports" replace />
    }
  ]
}
