import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const InventoryRulesView = lazy(
  () => import(/* webpackChunkName: "inventory-rules" */ /* webpackPrefetch: true */ './views/Index')
)
const NewInventoryRuleView = lazy(
  () => import(/* webpackChunkName: "new-inventory-rule" */ /* webpackPrefetch: true */ './views/New')
)
const EditInventoryRuleView = lazy(
  () => import(/* webpackChunkName: "edit-inventory-rule" */ /* webpackPrefetch: true */ './views/Edit')
)

export const InventoryRulesRoutes = {
  path: '/inventory-rules',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <InventoryRulesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewInventoryRuleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':inventoryRuleId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditInventoryRuleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/inventory-rules" replace />
    }
  ]
}
