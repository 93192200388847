import { gql, InMemoryCache } from '@apollo/client'
import { flatten, isEmpty, max, camelCase } from 'lodash'
import { defineMessages } from 'react-intl'

import {
  IPrevBookingsDashboard,
  IBookingsDashboardSubscription,
  TPassengerWithTickets,
  IMovedBooking
} from 'features/Bookings/types'
import {
  GTBookingsDashboardData,
  GTBookingsDashboardDataInfo,
  GTBookingsDashboardDataMethodInfo,
  GTBookingV2Status,
  GTBookingV2,
  GTBookingV2TicketStatus,
  GTBookingV2Ticket,
  GTBookingV2Trip,
  GTBookingV2Payment,
  GTBookingV2TicketAncillaryType,
  GTBookingV2PaymentTicket,
  GTBookingV2SupportInteraction,
  GTBookingV2Refund,
  GTBookingV2PaymentStatus
} from 'gql/types.generated'
import {
  canBeApplied as canApplyInsuranceForTicket,
  canBeCancelled as canCancelInsuranceForTicket
} from 'resolvers/insurance'
import { has } from 'utils/array'

export const CHANGE_TRIP_BOOKING_SEAT = gql`
  mutation ChangeTripBookingSeat($tripId: ID!, $bookingId: ID!, $oldSeatId: ID!, $newSeatId: ID!, $segmentId: ID!) {
    changeTripBookingSeat(
      input: {
        id: $tripId
        booking: { id: $bookingId, oldSeatID: $oldSeatId, newSeatID: $newSeatId, segmentID: $segmentId }
      }
    )
  }
`

export const paymentStatusMessages = defineMessages({
  [GTBookingV2PaymentStatus.Paid]: {
    id: 'payment.status.paid',
    defaultMessage: 'Paid'
  },
  [GTBookingV2PaymentStatus.ChargedBack]: {
    id: 'payment..status.chargedBack',
    defaultMessage: 'Charged back'
  },
  [GTBookingV2PaymentStatus.Refunded]: {
    id: 'payment.status.refunded',
    defaultMessage: 'Refunded'
  },
  [GTBookingV2PaymentStatus.PaymentFailed]: {
    id: 'payment.status.paymentFailed',
    defaultMessage: 'Payment failed'
  },
  [GTBookingV2PaymentStatus.PaymentDeclined]: {
    id: 'payment.status.paymentDeclined',
    defaultMessage: 'Payment declined'
  }
})

export const bookingHasReservation = (booking: GTBookingV2): boolean => {
  return (
    booking.status === GTBookingV2Status.Reserved ||
    booking.status === GTBookingV2Status.Confirmed ||
    booking.status === GTBookingV2Status.PartiallyConfirmed
  )
}

export const isConfirmed = (booking: GTBookingV2): boolean => {
  return booking.status === GTBookingV2Status.Confirmed || booking.status === GTBookingV2Status.PartiallyConfirmed
}

export const isReserved = (booking: GTBookingV2): boolean => {
  return booking.status === GTBookingV2Status.Reserved
}

export const isPartiallyConfirmed = (booking: GTBookingV2): boolean => {
  return booking.status === GTBookingV2Status.PartiallyConfirmed
}

export const bookingHasPaidStatus = (booking: GTBookingV2): boolean => {
  return booking.status === GTBookingV2Status.Paid
}

export const isCancelled = (booking: GTBookingV2): boolean => {
  return booking.status === GTBookingV2Status.Cancelled
}

export const isTicketCancelled = (ticket: Pick<GTBookingV2Ticket, 'status'>): boolean => {
  return ticket.status === GTBookingV2TicketStatus.Cancelled
}

export const getPaidTicketsByTrips = ({
  tickets,
  trips,
  payments
}: {
  tickets: GTBookingV2Ticket[]
  trips: GTBookingV2Trip[]
  payments: GTBookingV2Payment[]
}): GTBookingV2Ticket[] => {
  return tickets.filter((ticket) => {
    if (isTicketCancelled(ticket)) return false

    const ticketTrip = trips.find((trip) => trip.id === ticket.trip?.id)

    if (!isEmpty(ticketTrip)) {
      const ticketPayment = payments.find((payment) => {
        return (
          payment.status === GTBookingV2PaymentStatus.Paid &&
          !isEmpty(payment.tickets.find(({ id }) => id === ticket.id))
        )
      })

      return !isEmpty(ticketPayment)
    }

    return false
  })
}

export const hasNotPaidReturnTrip = (booking: GTBookingV2): boolean => {
  return (
    booking.returnTrips.length > 0 &&
    isPartiallyConfirmed(booking) &&
    isEmpty(
      getPaidTicketsByTrips({
        tickets: booking.tickets,
        trips: booking.returnTrips,
        payments: booking.payments
      })
    )
  )
}

export const getUnpaidTicketsForPayment = (booking: GTBookingV2): GTBookingV2Ticket[] | undefined => {
  if (isReserved(booking)) {
    return booking.tickets.filter((ticket) => !isTicketCancelled(ticket))
  } else if (hasNotPaidReturnTrip(booking)) {
    return booking.tickets.filter((ticket) => {
      if (isTicketCancelled(ticket)) return false

      const ticketTrip = booking.returnTrips.find((trip) => trip.id === ticket.trip?.id)

      return !isEmpty(ticketTrip)
    })
  }
}

export const getBookingAmountByTickets = ({
  tickets
}: {
  tickets: GTBookingV2Ticket[]
}): { total: number; subtotal: number; vat: number; discount: number } => {
  let subtotal = 0
  let vat = 0
  let discount = 0

  tickets.forEach((ticket) => {
    if (!isTicketCancelled(ticket)) {
      subtotal += ticket.subtotal
      vat += ticket.vat
      discount += ticket.discount
    }
  })

  return {
    total: subtotal + vat - discount,
    subtotal,
    vat,
    discount
  }
}

export const findValidTicketsByTrip = (tickets: GTBookingV2Ticket[], trip: GTBookingV2Trip): GTBookingV2Ticket[] => {
  return tickets.filter((ticket) => ticket.trip?.id === trip.id && !isTicketCancelled(ticket))
}

export const findValidTicketsByTrips = (
  tickets: GTBookingV2Ticket[],
  trips: GTBookingV2Trip[]
): GTBookingV2Ticket[] => {
  return flatten(
    trips.map((trip) => {
      return findValidTicketsByTrip(tickets, trip)
    })
  )
}

export const isSameTrip = (departureTrips: GTBookingV2Trip[], returnTrips: GTBookingV2Trip[]): boolean => {
  return departureTrips.every((departureTrip) => {
    return returnTrips.some((returnTrip) => {
      return departureTrip.id === returnTrip.id
    })
  })
}

const DEFAULT_NUMBERS_BY_STATUS = {
  online: {
    count: 0,
    amount: 0
  },
  offline: {
    count: 0,
    amount: 0
  }
}
export const getTotalDashboard = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): GTBookingsDashboardDataMethodInfo => {
  reserved = reserved || DEFAULT_NUMBERS_BY_STATUS
  confirmed = confirmed || DEFAULT_NUMBERS_BY_STATUS
  partiallyConfirmed = partiallyConfirmed || DEFAULT_NUMBERS_BY_STATUS

  return {
    online: {
      count: reserved.online.count + confirmed.online.count + partiallyConfirmed.online.count,
      amount: reserved.online.amount + confirmed.online.amount + partiallyConfirmed.online.amount
    },
    offline: {
      count: reserved.offline.count + confirmed.offline.count + partiallyConfirmed.offline.count,
      amount: reserved.offline.amount + confirmed.offline.amount + partiallyConfirmed.offline.amount
    }
  }
}

export const getTotalDashboardByStatus = ({
  online,
  offline
}: {
  online: GTBookingsDashboardDataInfo
  offline: GTBookingsDashboardDataInfo
}): GTBookingsDashboardDataInfo => {
  online = online || DEFAULT_NUMBERS_BY_STATUS.online
  offline = offline || DEFAULT_NUMBERS_BY_STATUS.offline

  return {
    count: offline.count + online.count,
    amount: offline.amount + online.amount
  }
}

export const getPassengersByTickets = (booking: Pick<IMovedBooking, 'tickets'>): TPassengerWithTickets[] => {
  return booking.tickets.reduce((result, ticket): TSFixMe => {
    if (isTicketCancelled(ticket)) return result

    const passengerWithTicket = result.find((passengerWithTickets): TSFixMe => {
      return (
        ticket.passenger?.firstName === passengerWithTickets.firstName &&
        ticket.passenger?.lastName === passengerWithTickets.lastName &&
        ticket.passenger?.type.name === passengerWithTickets.type.name
      )
    })

    if (!passengerWithTicket) {
      return [
        ...result,
        {
          ...ticket.passenger,
          tickets: [ticket]
        }
      ]
    } else {
      let ticketAssigned = false
      const passengersWithTickets = result.map((passengerWithTickets): TSFixMe => {
        const tripTicket = passengerWithTickets.tickets?.find(({ trip }): TSFixMe => trip?.id === ticket.trip?.id)
        const isPassengerTicket =
          ticket.passenger?.firstName === passengerWithTickets.firstName &&
          ticket.passenger?.lastName === passengerWithTickets.lastName &&
          ticket.passenger?.type.name === passengerWithTickets.type.name

        if (isPassengerTicket && !tripTicket && !ticketAssigned) {
          ticketAssigned = true

          return {
            ...passengerWithTickets,
            tickets: [...passengerWithTickets.tickets, ticket]
          }
        }

        return passengerWithTickets
      })

      if (!ticketAssigned) {
        return [
          ...passengersWithTickets,
          {
            ...ticket.passenger,
            tickets: [ticket]
          }
        ]
      }

      return passengersWithTickets
    }
  }, [] as TPassengerWithTickets[]) as TPassengerWithTickets[]
}

const getRandomId = (): string => {
  return Math.random().toString().replace('.', '')
}

const BOOKINGS_DASHBOARD_STATUS = [
  GTBookingV2Status.Confirmed,
  GTBookingV2Status.PartiallyConfirmed,
  GTBookingV2Status.Reserved,
  GTBookingV2Status.Failed,
  GTBookingV2Status.Cancelled
]

export const updateBookingsDashboardWithSubscription = (
  prev: IPrevBookingsDashboard,
  { subscriptionData }: { subscriptionData: IBookingsDashboardSubscription }
): IPrevBookingsDashboard => {
  if (!subscriptionData.data) return prev

  const { bookingsDashboard } = subscriptionData.data

  let bookingsDashboardCopy = Object.assign({}, prev.bookingsDashboard)
  const now = new Date()
  const createdAt = new Date(bookingsDashboard.createdAt)
  const isBookingCreatedToday = now.getDate() === createdAt.getDate()
  const { status: previousStatus, amount: previousAmount } = bookingsDashboard.previous
  const previousStatusKey = camelCase(previousStatus) as Exclude<keyof GTBookingsDashboardData, '__typename'>
  if (has(BOOKINGS_DASHBOARD_STATUS, previousStatus)) {
    if (isBookingCreatedToday) {
      const todayNumbersBySaleChannel = prev.bookingsDashboard.day[previousStatusKey]?.[bookingsDashboard.saleChannel]
      const currentAmount = todayNumbersBySaleChannel?.amount || 0
      const currentCount = todayNumbersBySaleChannel?.count || 0
      const newValues = {
        amount: max([currentAmount - previousAmount, 0]),
        count: max([currentCount - 1, 0])
      }
      bookingsDashboardCopy = {
        ...bookingsDashboardCopy,
        day: {
          ...bookingsDashboardCopy.day,
          [previousStatusKey]: {
            ...bookingsDashboardCopy.day[previousStatusKey],
            [bookingsDashboard.saleChannel]: newValues,
            total: getTotalDashboardByStatus({
              ...bookingsDashboardCopy.day[previousStatusKey],
              [bookingsDashboard.saleChannel]: newValues
            })
          }
        }
      }
    }
    const currentMonthlyAmount =
      prev.bookingsDashboard.month[previousStatusKey][bookingsDashboard.saleChannel].amount || 0
    const currentMonthlyCount =
      prev.bookingsDashboard.month[previousStatusKey][bookingsDashboard.saleChannel].count || 0
    const newValues = {
      amount: max([currentMonthlyAmount - previousAmount, 0]),
      count: max([currentMonthlyCount - 1, 0])
    }
    bookingsDashboardCopy = {
      ...bookingsDashboardCopy,
      month: {
        ...bookingsDashboardCopy.month,
        [previousStatusKey]: {
          ...bookingsDashboardCopy.month[previousStatusKey],
          [bookingsDashboard.saleChannel]: newValues,
          total: getTotalDashboardByStatus({
            ...bookingsDashboardCopy.month[previousStatusKey],
            [bookingsDashboard.saleChannel]: newValues
          })
        }
      }
    }
  }

  const newStatusKey = camelCase(bookingsDashboard.status) as Exclude<keyof GTBookingsDashboardData, '__typename'>
  if (has(BOOKINGS_DASHBOARD_STATUS, bookingsDashboard.status)) {
    const newAmount = bookingsDashboard.amount

    if (isBookingCreatedToday) {
      const currentAmount = prev.bookingsDashboard.day[newStatusKey][bookingsDashboard.saleChannel].amount || 0
      const currentCount = prev.bookingsDashboard.day[newStatusKey][bookingsDashboard.saleChannel].count || 0
      const newValues = {
        amount: currentAmount + newAmount,
        count: currentCount + 1
      }
      bookingsDashboardCopy = {
        ...bookingsDashboardCopy,
        day: {
          ...bookingsDashboardCopy.day,
          [newStatusKey]: {
            ...bookingsDashboardCopy.day[newStatusKey],
            [bookingsDashboard.saleChannel]: newValues,
            total: getTotalDashboardByStatus({
              ...bookingsDashboardCopy.day[newStatusKey],
              [bookingsDashboard.saleChannel]: newValues
            })
          }
        }
      }
    }
    const currentMonthlyAmount = prev.bookingsDashboard.month[newStatusKey][bookingsDashboard.saleChannel].amount || 0
    const currentMonthlyCount = prev.bookingsDashboard.month[newStatusKey][bookingsDashboard.saleChannel].count || 0
    const newValues = {
      amount: currentMonthlyAmount + newAmount,
      count: currentMonthlyCount + 1
    }
    bookingsDashboardCopy = {
      ...bookingsDashboardCopy,
      month: {
        ...bookingsDashboardCopy.month,
        [newStatusKey]: {
          ...bookingsDashboardCopy.month[newStatusKey],
          [bookingsDashboard.saleChannel]: newValues,
          total: getTotalDashboardByStatus({
            ...bookingsDashboardCopy.month[newStatusKey],
            [bookingsDashboard.saleChannel]: newValues
          })
        }
      }
    }
  }

  bookingsDashboardCopy = {
    ...bookingsDashboardCopy,
    day: {
      ...bookingsDashboardCopy.day,
      total: getTotalDashboard(bookingsDashboardCopy.day),
      onlinePercent: getOnlinePercent(bookingsDashboardCopy.day),
      offlinePercent: getOfflinePercent(bookingsDashboardCopy.day)
    },
    month: {
      ...bookingsDashboardCopy.month,
      total: getTotalDashboard(bookingsDashboardCopy.month),
      onlinePercent: getOnlinePercent(bookingsDashboardCopy.month),
      offlinePercent: getOfflinePercent(bookingsDashboardCopy.month)
    }
  }

  return Object.assign({}, prev, {
    bookingsDashboard: bookingsDashboardCopy
  })
}

export const getOfflinePercent = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): number => {
  const total = getTotalDashboard({ reserved, confirmed, partiallyConfirmed })
  const totalCount = total.online.count + total.offline.count

  if (totalCount === 0) return 0

  return total.offline.count / totalCount
}

export const getOnlinePercent = ({
  reserved,
  confirmed,
  partiallyConfirmed
}: {
  reserved: GTBookingsDashboardDataMethodInfo
  confirmed: GTBookingsDashboardDataMethodInfo
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
}): number => {
  const total = getTotalDashboard({ reserved, confirmed, partiallyConfirmed })
  const totalCount = total.online.count + total.offline.count

  if (totalCount === 0) return 0

  return total.online.count / totalCount
}

export const canApplyInsurance = (booking: GTBookingV2): boolean => {
  const ticketWithoutInsurance = booking.tickets.find((ticket) => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }) => type === GTBookingV2TicketAncillaryType.Insurance)
      return !insurance || canApplyInsuranceForTicket(insurance.status, false)
    }

    return false
  })

  return !isEmpty(ticketWithoutInsurance)
}

export const canCancelInsurance = (booking: GTBookingV2): boolean => {
  const ticketWithInsurance = booking.tickets.find((ticket) => {
    if (!isTicketCancelled(ticket)) {
      const ancillaries = ticket.ancillaries || []
      const insurance = ancillaries.find(({ type }) => type === GTBookingV2TicketAncillaryType.Insurance)
      return insurance && canCancelInsuranceForTicket(insurance.status)
    }

    return false
  })

  return !isEmpty(ticketWithInsurance)
}

export const getTotalTicketPayment = ({
  subtotal,
  vat,
  discount = 0
}: {
  subtotal: number
  vat: number
  discount: number
}): number => {
  return subtotal + vat - discount
}

const BOOKING_REFUNDS_FRAGMENT = gql`
  fragment BookingRefunds on BookingV2 {
    refunds {
      paymentID
      amount
    }
  }
`
export const resolvers = {
  Mutation: {
    updateBookingStatus(
      _root: never,
      { status, bookingId }: { status: string; bookingId: string },
      { cache }: { cache: InMemoryCache }
    ): null {
      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingStatus on BookingV2 {
            status
          }
        `,
        data: {
          __typename: 'BookingV2',
          status
        }
      })

      return null
    },
    addRefundsToBooking(
      _root: never,
      { bookingId, refunds }: { bookingId: string; refunds: { paymentId: string; amount: number }[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const data = cache.readFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: BOOKING_REFUNDS_FRAGMENT
      }) as { refunds: GTBookingV2Refund[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: BOOKING_REFUNDS_FRAGMENT,
        data: {
          __typename: 'BookingV2',
          refunds: [
            ...data.refunds,
            ...refunds.map((refund): TSFixMe => {
              return {
                paymentID: refund.paymentId,
                amount: refund.amount
              }
            })
          ]
        }
      })

      return null
    },
    updateTicketStatus(
      _root: never,
      { status, ticketId }: { status: string; ticketId: string },
      { cache }: { cache: InMemoryCache }
    ): null {
      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2Ticket',
          id: ticketId
        }),
        fragment: gql`
          fragment TicketStatus on BookingV2Ticket {
            status
          }
        `,
        data: {
          __typename: 'BookingV2Ticket',
          status
        }
      })

      return null
    },
    updateBookingPayment(
      _root: never,
      {
        chargeId,
        last4,
        bookingId,
        method,
        processor,
        tickets = []
      }: {
        chargeId: string
        last4: string
        bookingId: string
        method: string
        processor: string
        tickets: GTBookingV2PaymentTicket[]
      },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newPayment = {
        __typename: 'BookingV2Payment',
        id: getRandomId(),
        method,
        processor,
        status: GTBookingV2PaymentStatus.Paid,
        chargeID: chargeId || null,
        last4: last4 || null,
        authCode: null,
        tickets: tickets.map((ticket): TSFixMe => {
          return {
            __typename: 'BookingV2PaymentTicket',
            id: ticket.id,
            subtotal: ticket.subtotal,
            vat: ticket.vat,
            discount: ticket.discount
          }
        })
      }

      const { payments } = cache.readFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on BookingV2 {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `
      }) as { payments: GTBookingV2Payment[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingPayment on BookingV2 {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `,
        data: {
          __typename: 'BookingV2',
          status: GTBookingV2Status.Confirmed,
          payments: [...payments, newPayment]
        }
      })

      return null
    },
    updateBookingPaymentMethod(
      _root: never,
      { bookingId, method, tickets }: { bookingId: string; method: string; tickets: GTBookingV2PaymentTicket[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newPayment = {
        __typename: 'BookingV2Payment',
        id: getRandomId(),
        method,
        processor: null,
        status: GTBookingV2PaymentStatus.Paid,
        chargeID: null,
        authCode: null,
        last4: null,
        tickets: tickets.map((ticket: GTBookingV2PaymentTicket) => {
          return {
            __typename: 'BookingV2PaymentTicket',
            id: ticket.id,
            subtotal: ticket.subtotal,
            vat: ticket.vat,
            discount: ticket.discount
          }
        })
      }

      const { payments } = cache.readFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingV2Payment on BookingV2 {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `
      }) as { payments: GTBookingV2Payment[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingV2Payment on BookingV2 {
            status
            payments {
              id
              method
              processor
              status
              chargeID
              authCode
              last4
              tickets {
                id
                subtotal
                vat
                discount
              }
            }
          }
        `,
        data: {
          __typename: 'BookingV2',
          status: GTBookingV2Status.Confirmed,
          payments: [...payments, newPayment]
        }
      })
      return null
    },
    addBookingSupportInteraction(
      _root: never,
      {
        bookingId,
        username,
        callStatus,
        notificationChannel,
        notes
      }: { bookingId: string; username: string; callStatus: string; notificationChannel: string; notes: string[] },
      { cache }: { cache: InMemoryCache }
    ): null {
      const newSupportInteraction = {
        __typename: 'BookingV2SupportInteraction',
        createdAt: new Date(),
        user: {
          __typename: 'BookingV2SupportInteractionUser',
          username
        },
        callStatus,
        notificationChannel,
        notes
      }

      const { supportInteractions } = cache.readFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingSupportInteractions on BookingV2 {
            supportInteractions {
              callStatus
              createdAt
              notes
              notificationChannel
              user {
                username
              }
            }
          }
        `
      }) as { supportInteractions: GTBookingV2SupportInteraction[] }

      cache.writeFragment({
        id: cache.identify({
          __typename: 'BookingV2',
          id: bookingId
        }),
        fragment: gql`
          fragment BookingSupportInteractions on BookingV2 {
            supportInteractions {
              callStatus
              createdAt
              notes
              notificationChannel
              user {
                username
              }
            }
          }
        `,
        data: {
          __typename: 'BookingV2',
          supportInteractions: [...supportInteractions, newSupportInteraction]
        }
      })

      return null
    }
  }
}
