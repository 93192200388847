import { ReactElement, Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BusinessCustomerListView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "BusinessCustomerListView" */ /* webpackPrefetch: true */ './views/Index')
)
const NewBusinessCustomerView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "NewBusinessCustomerView" */ /* webpackPrefetch: true */ './views/New')
)
const EditBusinessCustomerView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "EditBusinessCustomerView" */ /* webpackPrefetch: true */ './views/Edit')
)

export const BusinessCustomerRoutes = [
  {
    path: '/business-customers',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <BusinessCustomerListView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':businessCustomerId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditBusinessCustomerView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewBusinessCustomerView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  }
]
