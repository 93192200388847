import { PERMISSIONS, FEATURES } from 'features/Roles/components/Permissions/constants'
import { IRole } from 'features/Users/types'

enum ACTIONS {
  SEND_EMAIL_BOOKING = 'SendEmailBooking',
  ADD_BOOKING_TICKETS_INSURANCE = 'AddBookingTicketsInsurance',
  CANCEL_BOOKING_TICKETS_INSURANCE = 'CancelBookingTicketsInsurance',
  REGISTER_BOOKING_SUPPORT_INTERACTION = 'RegisterBookingSupportInteraction',
  CANCEL_BOOKING_TICKETS = 'cancelBookingTickets',
  START_MOVE_BOOKING_TRIP_CHANGE = 'startMoveBookingTripChange',
  START_BOOKING_TRIP_CHANGE = 'startBookingTripChange',
  CONFIRM_BOOKING_PAYMENT_FROM_ADMIN = 'confirmBookingPaymentFromAdmin',
  ACTIVATE_TRIP_SALE = 'activateTripSale',
  DEACTIVATE_TRIP_SALE = 'deactivateTripSale',
  CHANGE_TRIP_VEHICLE = 'changeTripVehicle',
  CANCEL_TRIP = 'cancelTrip',
  SEND_EMAIL_COUPON = 'sendEmailCoupon',
  CREATE_TRIP = 'createRouteServiceSingleTrip',
  SEND_SMS_COUPON = 'sendSMSCoupon',
  INCIDENCE_SUBCAMPAIGNS_READ = 'incidenceSubcampaignsRead',
  DATABASE_SUBCAMPAIGNS_READ = 'databaseSubcampaignsRead',
  CREATE_SUBCAMPAIGN = 'createCampaignSubcampaign',
  CHANGE_TRIP_DRIVER = 'changeTripDriver',
  RESET_USER_PASSWORD = 'resetUserPassword',
  CHANGE_TRIP_CONFIRMATION_MODALITIES = 'changeTripConfirmationModalities'
}

export const canRead = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.READ}`)
  )
}

export const canReadIncidenceSubcampaigns = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.CAMPAIGN}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.CAMPAIGN}:${ACTIONS.INCIDENCE_SUBCAMPAIGNS_READ}`)
  )
}

export const canReadDbSubcampaigns = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.CAMPAIGN}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.CAMPAIGN}:${ACTIONS.DATABASE_SUBCAMPAIGNS_READ}`)
  )
}

export const canCreateSubcampaign = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.CAMPAIGN}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.CAMPAIGN}:${ACTIONS.CREATE_SUBCAMPAIGN}`)
  )
}

export const canDeactivateSubcampaignCoupons = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.CAMPAIGN}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.CAMPAIGN}:${PERMISSIONS.DEACTIVATE_CAMPAIGN_SUBCAMPAIGN_COUPON}`)
  )
}

export const canReadInventory = (permissions: string[]): boolean => {
  return (
    canRead(FEATURES.INVENTORY, permissions) ||
    canRead(FEATURES.WAREHOUSE, permissions) ||
    canRead(FEATURES.BASE, permissions)
  )
}

export const canReadCart = (permissions: string[]): boolean => {
  return canRead(FEATURES.INVENTORY, permissions) || canRead(FEATURES.CART, permissions)
}

export const canDownloadBookings = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.BOOKINGS_READ}`)
  )
}

export const canDownloadOffers = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.OFFER_READ}`)
  )
}

export const canDownloadDemands = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.DEMAND_READ}`)
  )
}

export const canDownloadUsers = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.USERS_READ}`)
  )
}

export const canDownloadTickets = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.TICKETS_READ}`)
  )
}

export const canDownloadOccupancy = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.OCCUPANCY_READ}`)
  )
}

export const canDownloadBusinessBookings = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.BUSINESS_BOOKINGS_READ}`)
  )
}

export const canDownloadSales = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.REPORT}:${PERMISSIONS.SALES_READ}`)
  )
}

export const canWrite = (feature: string, permissions: string[]): boolean => {
  return permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`)
}

export const canWriteInventory = (permissions: string[]): boolean => {
  return (
    canWrite(FEATURES.INVENTORY, permissions) ||
    canWrite(FEATURES.WAREHOUSE, permissions) ||
    canWrite(FEATURES.BASE, permissions)
  )
}

export const canWriteWarehouse = (permissions: string[]): boolean => {
  return canWrite(FEATURES.INVENTORY, permissions) || canWrite(FEATURES.WAREHOUSE, permissions)
}

export const canWriteBase = (permissions: string[]): boolean => {
  return canWrite(FEATURES.INVENTORY, permissions) || canWrite(FEATURES.BASE, permissions)
}

export const canWriteCart = (permissions: string[]): boolean => {
  return canWrite(FEATURES.INVENTORY, permissions) || canWrite(FEATURES.CART, permissions)
}

export const canCancelInventory = (permissions: string[]): boolean => {
  return (
    canCancel(FEATURES.INVENTORY, permissions) ||
    canCancel(FEATURES.WAREHOUSE, permissions) ||
    canCancel(FEATURES.BASE, permissions)
  )
}

export const canCancelWarehouse = (permissions: string[]): boolean => {
  return canCancel(FEATURES.INVENTORY, permissions) || canCancel(FEATURES.WAREHOUSE, permissions)
}

export const canCancelBase = (permissions: string[]): boolean => {
  return canCancel(FEATURES.INVENTORY, permissions) || canCancel(FEATURES.BASE, permissions)
}

export const canCancelCart = (permissions: string[]): boolean => {
  return canCancel(FEATURES.INVENTORY, permissions) || canCancel(FEATURES.CART, permissions)
}

export const canCancelScheduledCommand = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.SCHEDULER}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.SCHEDULER}:${PERMISSIONS.CANCEL_SCHEDULED_COMMAND}`)
  )
}

export const canScheduleCommand = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.SCHEDULER}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.SCHEDULER}:${PERMISSIONS.SCHEDULE_COMMAND}`)
  )
}

export const canReadDrawerBookings = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.DRAWER_BOOKINGS_READ}`)
  )
}

export const canReadDrawerSales = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.SALE}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.SALE}:${PERMISSIONS.DRAWER_SALES_READ}`)
  )
}

export const canReadDrawerKreditSales = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.SALE}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.SALE}:${PERMISSIONS.DRAWER_KREDIT_SALES_READ}`)
  )
}

export const canReadPassengers = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.TRIP_BOOKINGS_READ}`)
  )
}

export const canCancelTickets = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.CANCEL_BOOKING_TICKETS}`)
  )
}

export const canEditBooking = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.START_BOOKING_TRIP_CHANGE}`)
  )
}

export const canConfirmBookingPayment = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.CONFIRM_BOOKING_PAYMENT_FROM_ADMIN}`)
  )
}

export const canMoveBookingTrip = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.START_MOVE_BOOKING_TRIP_CHANGE}`)
  )
}

export const canApplyInsurance = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.ADD_BOOKING_TICKETS_INSURANCE}`)
  )
}

export const canCancelInsurance = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.CANCEL_BOOKING_TICKETS_INSURANCE}`)
  )
}

export const canRegisterSupportInteraction = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${ACTIONS.REGISTER_BOOKING_SUPPORT_INTERACTION}`)
  )
}

export const canRegisterBookingTaxInvoiced = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.BOOKING}:${PERMISSIONS.REGISTER_BOOKING_TAX_INVOICED_FROM_ADMIN}`)
  )
}

export const canResendCoupons = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.EMAIL}:${ACTIONS.SEND_EMAIL_COUPON}`) ||
    permissions.includes(`${FEATURES.SMS}:${ACTIONS.SEND_SMS_COUPON}`)
  )
}

export const canSendEmailBooking = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.EMAIL}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.EMAIL}:${ACTIONS.SEND_EMAIL_BOOKING}`)
  )
}

export const canShowBookingsDashboard = (permissions: string[]): boolean => {
  return permissions.includes(`${FEATURES.DASHBOARD}:${PERMISSIONS.BOOKINGS_DASHBOARD_READ}`)
}

export const canShowInsuranceSettings = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.ANCILLARIES}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.ANCILLARIES}:${PERMISSIONS.READ}`)
  )
}

export const canChangeCrewUsers = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.CHANGE_TRIP_USER_IDS}`)
  )
}

export const canCancel = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.CANCEL}`)
  )
}

export const canDeactivate = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.DEACTIVATE}`)
  )
}

export const canActivate = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.ACTIVATE}`)
  )
}
export const canEnablePassengerProperty = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.ENABLE_PASSENGER_PROPERTY}`)
  )
}

export const canDisablePassengerProperty = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.DISABLE_PASSENGER_PROPERTY}`)
  )
}

export const canEnablePassengerType = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.ENABLE_PASSENGER_TYPE}`)
  )
}

export const canDisablePassengerType = (feature: string, permissions: string[]): boolean => {
  return (
    permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${feature}:${PERMISSIONS.DISABLE_PASSENGER_TYPE}`)
  )
}

export const canActivateTripSale = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.ACTIVATE_TRIP_SALE}`)
  )
}

export const canDeactivateTripSale = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.DEACTIVATE_TRIP_SALE}`)
  )
}

export const canCreateTrip = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.SERVICE}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.SERVICE}:${ACTIONS.CREATE_TRIP}`)
  )
}

export const canEditTripDepartureTime = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.CHANGE_TRIP_ITINERARY_DEPARTURE_TIME}`)
  )
}

export const canCancelTrip = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.CANCEL_TRIP}`)
  )
}

export const canChangeTripVehicle = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.CHANGE_TRIP_VEHICLE}`)
  )
}

export const canChangeTripDriver = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.CHANGE_TRIP_DRIVER}`)
  )
}

export const canChangeTripConfirmationModalities = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.TRIP}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.TRIP}:${ACTIONS.CHANGE_TRIP_CONFIRMATION_MODALITIES}`)
  )
}

export const canDepositKreditsCorporateFund = (permissions: string[]): boolean => {
  return (
    permissions.includes(`${FEATURES.KREDIT}:${PERMISSIONS.ALL_ACCESS}`) ||
    permissions.includes(`${FEATURES.KREDIT}:${PERMISSIONS.DEPOSIT_KREDIT_CORPORATE_FUNDS_FROM_ADMIN}`)
  )
}

export const hasAllAccess = (feature: string, permissions: string[]): boolean => {
  return permissions.includes(`${feature}:${PERMISSIONS.ALL_ACCESS}`)
}

export const isSuperAdmin = (permissions: string[]): boolean => {
  return !(permissions ?? []).includes('matchCompany')
}

export const getPermissionIDsByRoles = (roles: IRole[]): string[] => {
  let permissionIDs: string[] = []

  ;(roles ?? []).forEach(({ permissions }: IRole) => {
    const newPermissions = permissions.filter((permission) => {
      return !permissionIDs.includes(permission.id)
    })

    permissionIDs = [...permissionIDs, ...newPermissions.map(({ id }) => id)]
  })

  return permissionIDs
}

export const canResetUserPassword = (permissions: string[]): boolean => {
  return canWrite(FEATURES.USER, permissions) || permissions.includes(`${FEATURES.USER}:${ACTIONS.RESET_USER_PASSWORD}`)
}
