import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const PreferredSeatsAncillariesView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "luggae-ancillaries" */ /* webpackPrefetch: true */ './views/PreferredSeatsAncillaries')
)
const NewPreferredSeatsView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "new-ancillary" */ /* webpackPrefetch: true */ './views/PreferredSeatsAncillaries/New')
)

const EditPreferredSeatsView = lazy(
  (): TSFixMe =>
    import(
      /* webpackChunkName: "edit-ancillary" */ /* webpackPrefetch: true */ './views/PreferredSeatsAncillaries/Edit'
    )
)

const LuggageAncillariesView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "luggae-ancillaries" */ /* webpackPrefetch: true */ './views/LuggageAncillaries')
)

const NewLuggageAncillaryView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "new-ancillary" */ /* webpackPrefetch: true */ './views/LuggageAncillaries/New')
)

const EditLuggageAncillaryView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "edit-ancillary" */ /* webpackPrefetch: true */ './views/LuggageAncillaries/Edit')
)

const TicketsInsuranceView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "tickets-insurance" */ /* webpackPrefetch: true */ './views/Index')
)
const AncillariesSettingsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "ancillaries-settings" */ /* webpackPrefetch: true */ './views/Settings')
)
const AddonServicesView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "ancillaries-addon-service" */ /* webpackPrefetch: true */ './views/AddonServices')
)
const NewAddonServiceView = lazy(
  (): TSFixMe =>
    import(
      /* webpackChunkName: "ancillaries-new-addon-service" */ /* webpackPrefetch: true */ './views/NewAddonService'
    )
)
const EditAddonServiceView = lazy(
  (): TSFixMe =>
    import(
      /* webpackChunkName: "ancillaries-edit-addon-service" */ /* webpackPrefetch: true */ './views/EditAddonService'
    )
)

export const AncillariesRoutes = {
  path: '/ancillaries',
  children: [
    {
      path: 'insurance',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <TicketsInsuranceView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'luggage',
      children: [
        {
          index: true,
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <LuggageAncillariesView />
              </Suspense>
            </AppContent>
          )
        },
        {
          path: 'edit/:ancillaryId',
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <EditLuggageAncillaryView />
              </Suspense>
            </AppContent>
          )
        },
        {
          path: 'new',
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <NewLuggageAncillaryView />
              </Suspense>
            </AppContent>
          )
        }
      ]
    },
    {
      path: 'preferred-seats',
      children: [
        {
          index: true,
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <PreferredSeatsAncillariesView />
              </Suspense>
            </AppContent>
          )
        },
        {
          path: 'edit/:ancillaryId',
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <EditPreferredSeatsView />
              </Suspense>
            </AppContent>
          )
        },
        {
          path: 'new',
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <NewPreferredSeatsView />
              </Suspense>
            </AppContent>
          )
        }
      ]
    },
    {
      path: 'settings',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <AncillariesSettingsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'services',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <AddonServicesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'services/new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewAddonServiceView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'services/:addonServiceId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditAddonServiceView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/ancillaries/insurance" replace />
    }
  ]
}
