import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const SitesView = lazy(() => import(/* webpackChunkName: "sites" */ /* webpackPrefetch: true */ './views/Index'))
const NewSiteView = lazy(() => import(/* webpackChunkName: "new-site" */ /* webpackPrefetch: true */ './views/New'))
const EditSiteView = lazy(() => import(/* webpackChunkName: "edit-site" */ /* webpackPrefetch: true */ './views/Edit'))
const SitesDashboardView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "sites-dashboard" */ /* webpackPrefetch: true */ 'features/SitesSales/views/Index')
)
const DrawersView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "drawers" */ /* webpackPrefetch: true */ 'features/SitesSales/views/Drawers')
)

export const SitesRoutes = [
  {
    path: '/sites',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <SitesView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewSiteView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':siteId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditSiteView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/sites" replace />
      }
    ]
  },
  {
    path: '/sites/sales',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <SitesDashboardView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'drawers',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <DrawersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/sites/sales" replace />
      }
    ]
  }
]
