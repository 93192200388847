import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CouponsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "coupons" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCouponView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-coupon" */ /* webpackPrefetch: true */ './views/New')
)
const EditCouponView = lazy(
  () => import(/* webpackChunkName: "edit-coupon" */ /* webpackPrefetch: true */ './views/Edit')
)
const SnacksCouponsView = lazy(
  () => import(/* webpackChunkName: "snacks-coupons" */ /* webpackPrefetch: true */ './views/SnacksCoupon')
)
const NewSnacksCouponView = lazy(
  () => import(/* webpackChunkName: "new-snacks-coupon" */ /* webpackPrefetch: true */ './views/NewSnacksCoupon')
)
const EditSnacksCouponView = lazy(
  () => import(/* webpackChunkName: "edit-snacks-coupon" */ /* webpackPrefetch: true */ './views/EditSnacksCoupon')
)

export const CouponsRoutes = [
  {
    path: '/coupons',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <CouponsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewCouponView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':couponId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditCouponView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/coupons" replace />
      }
    ]
  },
  {
    path: '/coupons/snacks',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <SnacksCouponsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewSnacksCouponView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':couponId',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditSnacksCouponView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/coupons/snacks" replace />
      }
    ]
  }
]
