import { useMutation, useApolloClient, gql } from '@apollo/client'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { LogoutIcon } from '@10xLabs/react-web/components/Icons/Logout'
import { resolvers as loginResolvers } from 'resolvers/login'
import { resolvers as urlsResolvers } from 'resolvers/urls'
import { ReactElement } from 'react'

const StyledHeader = styled.header`
  background-color: #ffffff;
  position: relative;
  z-index: 0;
  height: 75px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`

const LogoutWrapper = styled.div`
  cursor: pointer;

  svg {
    fill: #5d5c70;
  }
`

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

const REMOVE_TOKENS = gql`
  mutation RemoveTokens {
    removeTokens @client
  }
`

const SET_LAST_URL = gql`
  mutation SetLastUrl($url: String!) {
    setLastUrl(url: $url) @client
  }
`

const Header = (): ReactElement => {
  const navigate = useNavigate()
  const client = useApolloClient()
  client.addResolvers(loginResolvers)
  client.addResolvers(urlsResolvers)

  const [logout] = useMutation(LOGOUT)
  const [removeTokens] = useMutation(REMOVE_TOKENS)
  const [setLastUrl] = useMutation(SET_LAST_URL)

  const handleLogout = async (): Promise<void> => {
    logout()
    await setLastUrl({
      variables: {
        url: '/'
      }
    })
    await removeTokens()
    navigate('/login')
  }

  return (
    <StyledHeader>
      <LogoutWrapper onClick={handleLogout}>
        <LogoutIcon />
      </LogoutWrapper>
    </StyledHeader>
  )
}

export { Header }
