import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const DrStrangeLoginView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "dr-strange-login" */ /* webpackPrefetch: true */ './views/Login')
)
const DrStrangeView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "dr-strange" */ /* webpackPrefetch: true */ './views/Index')
)
const InsightsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "insights" */ /* webpackPrefetch: true */ './views/Insights')
)

export const DrStrangeRoutes = {
  path: '/dr-strange',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <DrStrangeView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'login',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <DrStrangeLoginView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'insights',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <InsightsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/dr-strange" replace />
    }
  ]
}
