import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const FareFamiliesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "fare-families" */ /* webpackPrefetch: true */ './views/Index')
)
const NewFareFamilyView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-fare-family" */ /* webpackPrefetch: true */ './views/New')
)

export const FareFamiliesRoutes = {
  path: '/fare-families',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <FareFamiliesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewFareFamilyView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/fare-families" replace />
    }
  ]
}
