import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const InventoriesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "inventories" */ /* webpackPrefetch: true */ './views/Index')
)
const NewInventoryView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-inventory" */ /* webpackPrefetch: true */ './views/New')
)
const EditInventoryView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-inventory" */ /* webpackPrefetch: true */ './views/Edit')
)
const InventoryItemsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "inventory-items" */ /* webpackPrefetch: true */ './views/Items')
)
const InventoryInboundsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "inventory-inbounds" */ /* webpackPrefetch: true */ './views/Inbounds')
)
const InventoryOutboundsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "inventory-outbounds" */ /* webpackPrefetch: true */ './views/Outbounds')
)
const InventoryPendingOrdersView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "inventory-pending-orders" */ /* webpackPrefetch: true */ './views/PendingOrders')
)
const InventoryWastesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "inventory-wastes" */ /* webpackPrefetch: true */ './views/Wastes')
)
const ReceiveBulkItemsView = lazy(
  (): TSFixMe =>
    import(
      /* webpackChunkName: "inventory-receive-bulk-items" */ /* webpackPrefetch: true */ './views/ReceiveBulkItems'
    )
)
const RemoveBulkItemsView = lazy(
  (): TSFixMe =>
    import(/* webpackChunkName: "inventory-remove-bulk-items" */ /* webpackPrefetch: true */ './views/RemoveBulkItems')
)
const ReceiveOrderView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "receive-order" */ /* webpackPrefetch: true */ './views/ReceiveOrder')
)
const EditOrderView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-order" */ /* webpackPrefetch: true */ './views/EditOrder')
)

export const InventoriesRoutes = [
  {
    path: '/inventories',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoriesView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewInventoryView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  },
  {
    path: '/inventories/:inventoryId',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditInventoryView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'items',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoryItemsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'inbounds',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoryInboundsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'outbounds',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoryOutboundsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'pending-orders',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoryPendingOrdersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'receive-bulk-items',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <ReceiveBulkItemsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'remove-bulk-items',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <RemoveBulkItemsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'wastes',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <InventoryWastesView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/inventories" replace />
      }
    ]
  },
  {
    path: '/inventories/:inventoryId/orders',
    children: [
      {
        path: ':orderId',
        children: [
          {
            index: true,
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <EditOrderView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: 'receive',
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <ReceiveOrderView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: '*',
            element: <Navigate to="/inventories" replace />
          }
        ]
      }
    ]
  }
]
