import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const CampaignsView = lazy(
  () => import(/* webpackChunkName: "campaigns" */ /* webpackPrefetch: true */ './views/Index')
)
const NewCampaignView = lazy(
  () => import(/* webpackChunkName: "new-campaign" */ /* webpackPrefetch: true */ './views/New')
)
const NewCouponTemplate = lazy(
  () => import(/* webpackChunkName: "new-coupon-template" */ /* webpackPrefetch: true */ './views/NewCouponTemplate')
)
const EditCampaignView = lazy(
  () => import(/* webpackChunkName: "edit-campaign" */ /* webpackPrefetch: true */ './views/Edit')
)

export const CampaignsRoutes = {
  path: '/campaigns',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <CampaignsView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewCampaignView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':campaignId',
      children: [
        {
          index: true,
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <EditCampaignView />
              </Suspense>
            </AppContent>
          )
        },
        {
          path: 'coupon-template',
          element: (
            <AppContent>
              <Suspense fallback={<PrefetchLoader />}>
                <NewCouponTemplate />
              </Suspense>
            </AppContent>
          )
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/campaigns" replace />
    }
  ]
}
