import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const TripsView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "trips" */ /* webpackPrefetch: true */ './views/Index')
)
const NewTripView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-trip" */ /* webpackPrefetch: true */ './views/New')
)
const EditTripView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-trip" */ /* webpackPrefetch: true */ './views/Edit')
)
const TripPassengersView = lazy(
  () => import(/* webpackChunkName: "trip-passengers" */ /* webpackPrefetch: true */ './views/TripPassengers')
)
const TripIncidenceCoupons = lazy(
  () =>
    import(/* webpackChunkName: "trip-incidence-coupons" */ /* webpackPrefetch: true */ './views/TripIncidenceCoupons')
)

export const TripsRoutes = [
  {
    path: '/trips',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <TripsView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  },
  {
    path: '/trips/create',
    element: (
      <AppContent>
        <Suspense fallback={<PrefetchLoader />}>
          <NewTripView />
        </Suspense>
      </AppContent>
    )
  },
  {
    path: '/trips/:tripId',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <EditTripView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'passengers',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <TripPassengersView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'incidence-coupons',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <TripIncidenceCoupons />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/trips" replace />
      }
    ]
  }
]
