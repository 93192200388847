import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const VehicleTemplatesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "vehicle-templates" */ /* webpackPrefetch: true */ './views/Index')
)
const NewVehicleTemplateView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-vehicle-template" */ /* webpackPrefetch: true */ './views/New')
)
const EditVehicleTemplateView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-vehicle-template" */ /* webpackPrefetch: true */ './views/Edit')
)

export const VehicleTemplatesRoutes = {
  path: '/vehicle-templates',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <VehicleTemplatesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewVehicleTemplateView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':vehicleTemplateId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditVehicleTemplateView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/vehicle-templates" replace />
    }
  ]
}
