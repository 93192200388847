import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const RolesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "roles" */ /* webpackPrefetch: true */ './views/Index')
)
const NewRoleView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-role" */ /* webpackPrefetch: true */ './views/New')
)
const EditRoleView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-role" */ /* webpackPrefetch: true */ './views/Edit')
)

export const RolesRoutes = {
  path: '/roles',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <RolesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewRoleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':roleId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditRoleView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/roles" replace />
    }
  ]
}
