import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const SubcampaignsView = lazy(
  () => import(/* webpackChunkName: "subcampaigns" */ /* webpackPrefetch: true */ './views/Index')
)
const SubcampaignsDatabaseCouponsView = lazy(
  () => import(/* webpackChunkName: "subcampaigns database" */ /* webpackPrefetch: true */ './views/DatabaseCoupons')
)
const SubcampaignsDatabaseCouponsDetailsView = lazy(
  () =>
    import(
      /* webpackChunkName: "subcampaigns database details" */ /* webpackPrefetch: true */ './views/DatabaseCouponsDetails'
    )
)
export const SubcampaignRoutes = [
  {
    path: '/subcampaigns',
    children: [
      {
        index: true,
        element: <Navigate to="incidences" />
      },
      {
        path: 'incidences',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <SubcampaignsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: 'database-coupons',
        children: [
          {
            index: true,
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <SubcampaignsDatabaseCouponsView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: ':subcampaignId',
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <SubcampaignsDatabaseCouponsDetailsView />
                </Suspense>
              </AppContent>
            )
          }
        ]
      }
    ]
  },
  {
    path: '/subcampaigns/*',
    element: <Navigate to="/subcampaigns" />
  }
]
