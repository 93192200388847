import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const UsersView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "users" */ /* webpackPrefetch: true */ './views/Index')
)
const NewUserView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "new-user" */ /* webpackPrefetch: true */ './views/New')
)
const EditUserView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "edit-user" */ /* webpackPrefetch: true */ './views/Edit')
)

export const UsersRoutes = {
  path: '/users',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <UsersView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewUserView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: ':userId',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <EditUserView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/users" replace />
    }
  ]
}
