import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const ExecutedChangesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "executed-changes" */ /* webpackPrefetch: true */ './views/Executed')
)
const ScheduledChangesView = lazy(
  (): TSFixMe => import(/* webpackChunkName: "scheduled-changes" */ /* webpackPrefetch: true */ './views/Scheduled')
)

export const ChangesRoutes = {
  path: '/changes',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <ExecutedChangesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'scheduled',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <ScheduledChangesView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/changes" replace />
    }
  ]
}
