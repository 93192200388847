import { Suspense, lazy } from 'react'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'
import { Navigate } from 'react-router-dom'

const KreditsView = lazy(() => import(/* webpackChunkName: "kredits" */ /* webpackPrefetch: true */ './views/Index'))
const TransactionsView = lazy(
  () => import(/* webpackChunkName: "kredits-transactions" */ /* webpackPrefetch: true */ './views/Transactions')
)

export const KreditsRoutes = [
  {
    path: '/kredits',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <KreditsView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  },
  {
    path: '/kredits/:kreditId',
    children: [
      {
        path: 'transactions',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <TransactionsView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: '*',
        element: <Navigate to="/kredits" replace />
      }
    ]
  }
]
